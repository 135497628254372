// import { vueKeycloak } from '@baloise/vue-keycloak'
import type { UserModule } from '~/types'

export const install: UserModule = async ({ app }) => {
  const { vueKeycloak } = await import('@baloise/vue-keycloak')

  app.use(vueKeycloak, {
    initOptions: {
      flow: 'standard', // default
      checkLoginIframe: false, // default
      onLoad: 'check-sso', // default
    },
    config: {
      url: 'https://auth.tradelikecrazy.ai/auth',
      realm: 'tlc',
      clientId: 'backoffice',
    },
  })
}
