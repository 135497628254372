<script setup lang="ts">
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import type { PropType } from 'vue'
import LogoWide from '~/assets/logo-wide.svg?component'

import type { NavigationItem } from '~/types'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  navigationItems: {
    type: Array as PropType<NavigationItem[]>,
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])

const close = () => {
  emit('update:modelValue', false)
}

const route = useRoute()

const isActive = (item: NavigationItem) => {
  return item.href ? route.path.startsWith(item.href) : false
}
</script>

<template>
  <TransitionRoot as="template" :show="props.modelValue">
    <Dialog as="div" class="fixed inset-0 flex z-40 lg:hidden" @close="close">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 backdrop-filter backdrop-blur-md" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div
          class="relative flex-1 flex flex-col max-w-[330px] w-full pt-5 pb-4 backdrop-filter backdrop-blur-2xl"
          style="
            background: linear-gradient(
              1deg,
              rgba(20, 101, 223, 0.15) 0.86%,
              rgba(19, 100, 222, 0.15) 0.87%,
              rgba(73, 139, 238, 0.15) 99.04%
            );
          "
        >
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                type="button"
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full"
                @click="close"
              >
                <!-- <span class="sr-only">Close sidebar</span> -->
                <!-- <XIcon class="h-6 w-6 text-white" aria-hidden="true" /> -->
              </button>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 flex items-center px-4">
            <LogoWide class="w-auto" />
          </div>
          <hr class="text-white opacity-[0.05] mx-4 mt-6" />
          <div class="mt-6 flex-1 h-0 overflow-y-auto py-4">
            <nav class="px-2 space-y-1">
              <template v-for="item in props.navigationItems" :key="item.name">
                <router-link
                  v-if="item.to"
                  :to="item.to"
                  class="relative group flex flex-row items-center gap-4 py-4 pl-6 opacity-70"
                  :aria-current="isActive(item) ? 'page' : undefined"
                  active-class="active-router-link !opacity-100"
                  @click="close"
                >
                  <component
                    :is="item.icon"
                    :name="item.icon"
                    :class="['relative z-10 mr-4.5 flex-shrink-0 size-8 menu-item__icon']"
                  />
                  <span class="relative z-10 uppercase text-lg text-primary-default">
                    {{ item.name }}
                  </span>
                  <svg
                    class="selection absolute left-[-20px] top-0 transform -translate-y-full transition transition-opacity opacity-0 duration-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="53"
                    height="40"
                    viewBox="0 0 53 40"
                    fill="none"
                  >
                    <path
                      d="M0.0566406 0.5C5.69767 41.5257 52.3643 39.4744 52.3643 39.4744H0.0566406V0.5Z"
                      fill="#BFD2FF"
                    />
                  </svg>
                  <div
                    class="selection absolute left-[-20px] top-1/2 w-[100%] h-[110%] transform -translate-y-1/2 bg-[#BFD2FF] z-5 opacity-0 rounded-r-[2.5rem] transition transition-opacity duration-500"
                  ></div>
                  <svg
                    class="selection absolute left-[-20px] bottom-0 transform translate-y-full transition transition-opacity opacity-0 duration-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="53"
                    height="39"
                    viewBox="0 0 53 39"
                    fill="none"
                  >
                    <path
                      d="M0.0566406 38.5C5.66448 -1.48261 52.0566 0.516521 52.0566 0.516521H0.0566406V38.5Z"
                      fill="#BFD2FF"
                    />
                  </svg>
                </router-link>
                <!-- <span
                  v-if="item.disabled"
                  target="_blank"
                  :class="['group menu-item opacity-50']"
                  :aria-current="isActive(item) ? 'page' : undefined"
                >
                  <component
                    :is="item.icon"
                    :name="item.icon"
                    :class="['mr-4.5 flex-shrink-0 h-5 w-5 menu-item__icon']"
                  />
                  {{ item.name }}
                </span> -->
              </template>
            </nav>
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.active-router-link {
  .selection {
    @apply !opacity-100;
  }
}
</style>

<style scoped>
.menu-item {
  @apply text-white  flex items-center px-4 py-4 font-semibold rounded-[0.625rem] transition duration-200 hover:bg-opacity-[0.05] hover:bg-white hover:text-white;
}
</style>

<style>
.menu-item {
  & .router-link-active {
    @apply bg-white bg-opacity-[0.05];

    .menu-item__icon {
      use {
        @apply fill-secondary;
      }
    }
  }
}
</style>
