<script setup lang="ts">
import { useKeycloak } from '@baloise/vue-keycloak'
import { setUser } from '@sentry/vue'
import { useUserStore } from '~/stores/user'
import { useCurrenciesStore } from '~/stores/currencies'

import MobileMenuIcon from '~/assets/icons/mobile-menu.svg?component'

import Dashboard from '~/assets/icons/menu/dashboard.svg?component'
import Announcements from '~/assets/icons/menu/announcements.svg?component'
import TeamCenter from '~/assets/icons/menu/team-center.svg?component'
import Packages from '~/assets/icons/menu/packages.svg?component'
import Structure from '~/assets/icons/menu/structure.svg?component'
import Support from '~/assets/icons/menu/support.svg?component'
import Team from '~/assets/icons/menu/team.svg?component'
import FastStart from '~/assets/icons/menu/faststart.svg?component'
import Transactions from '~/assets/icons/menu/transactions.svg?component'
import Settings from '~/assets/icons/menu/settings.svg?component'
import Marketing from '~/assets/icons/menu/marketing.svg?component'
import TheLayoutUserInfo from '~/components/layout/TheLayoutUserInfo.vue'

const isLoading = ref(true)

const userStore = useUserStore()
const currenciesStore = useCurrenciesStore()
const { isPending, decodedToken, isAuthenticated, keycloak } = useKeycloak()
watch(
  isPending,
  async (isPending) => {
    if (isPending) return

    if (!isAuthenticated.value) {
      const redirectUri = window.location.origin + window.location.pathname
      keycloak.login({ redirectUri })
      return
    }
    userStore.setDecodedToken(decodedToken.value)
    setUser({ email: decodedToken.value.email })
    await Promise.all([userStore.loadProfile(), currenciesStore.loadCurrencies()])
    showNewsPopup.value = !!userStore.profile?.accountRecalculated
    isLoading.value = false
  },
  {
    immediate: true,
  },
)
const { t, locale } = useI18n()
const navigation = computed(() => [
  { name: t('menu.dashboard'), to: `/${locale.value}/`, icon: Dashboard },
  { name: t('menu.blog'), to: `/${locale.value}/blog`, icon: Announcements },
  { name: t('menu.bonuses'), to: `/${locale.value}/statistics`, icon: Transactions },
  // { name: t('menu.contest'), to: '/' + locale.value + '/contest', icon: Leaderboard },
  { name: t('menu.structure'), to: `/${locale.value}/structure`, icon: Structure },
  { name: t('menu.teamCenter'), to: `/${locale.value}/teamCenter`, icon: TeamCenter },
  { name: t('menu.products'), to: `/${locale.value}/packages`, icon: Packages },
  { name: t('menu.marketing'), to: `/${locale.value}/marketing`, icon: Marketing },
  { name: t('menu.fastStart'), to: `/${locale.value}/fast-start`, icon: FastStart },
  { name: t('menu.traders'), to: `/${locale.value}/traders`, icon: Team },
  { name: t('menu.settings'), to: `/${locale.value}/settings`, icon: Settings },
  { name: t('menu.support'), to: `/${locale.value}/support`, icon: Support },
])

const sidebarOpen = ref(false)
const route = useRoute()
const showNewsPopup = ref(!!userStore.profile?.accountRecalculated)
</script>

<template>
  <div class="page-gradient -z-100 fixed w-full h-full bg-[#ECF2FF]"></div>
  <div class="">
    <div
      v-if="isLoading"
      class="max-h-screen h-screen w-full flex items-center justify-center overflow-hidden"
    >
      <AppSpinner :size="48" />
    </div>
    <div v-else class="w-full mb-20">
      <TheLayoutMobileSidebar v-model="sidebarOpen" :navigation-items="navigation" class="card" />
      <div
        class="absolute left-0 top-0 h-[100vh] "
      >
        <TheLayoutDesktopSidebar
          class="transition transition-all hover:w-72"
          :navigation-items="navigation"
        />
      </div>
      <main class="flex flex-col items-stretch lg:grid grid-cols-[150px_1fr] w-full">
        <div class="flex flex-row justify-between items-center lg:hidden">
          <div class="px-6 py-6 sm:py-8 flex flex-row">
            <button
              class="bg-primary-default/10 w-10 h-10 rounded-xl grid place-items-center"
              @click="sidebarOpen = true"
            >
              <MobileMenuIcon class="w-5 h-5" />
            </button>
          </div>
          <div></div>
          <div class="flex items-center gap-2">
            <TheLayoutUserInfo class="lg:hidden" />
            <LanguageSelect class="hidden lg:flex" />
          </div>
        </div>
        <div></div>
        <div class="page-index grid gap-y-4 sm:gap-y-8 md:grid-cols-1 sm:py-12 sm:px-8 px-4">
          <div class="w-full justify-between items-center flex">
            <h1 class="uppercase text-xl sm:text-3xl text-theme-black font-bold">
              {{ t(`navigation.title.${route.meta.title}`) }}
            </h1>
            <div class="flex items-center gap-5">
              <LanguageSelect />
              <TheLayoutUserInfo class="hidden lg:flex" />
            </div>
          </div>
          <div>
            <Suspense>
              <template v-if="isAuthenticated">

                <AppMergePopup :model-value="showNewsPopup" v-if="showNewsPopup"></AppMergePopup>
                <router-view v-else />
              </template>

            </Suspense>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<style scoped>
.page-gradient::before,
.page-gradient::after {
  content: '';
  @apply absolute;
}

.page-gradient {
  overflow: hidden;
  animation: gradient-scale 3s ease-in-out infinite alternate;
  transform: scale(1.5) translateZ(0);
  background: radial-gradient(
    81.6% 117.2% at 93.09% 93.47%,
    rgba(98, 72, 255, 0.03) 0%,
    rgba(229, 255, 72, 0.03) 36.93%,
    rgba(255, 72, 237, 0.05) 58.88%,
    rgba(72, 189, 255, 0.05) 70.83%,
    rgba(236, 242, 255, 0.55) 93.23%
  );
}

.page-gradient::before {
  @apply inset-0 transform translate-x-0;
  background: radial-gradient(
    81.6% 117.2% at 93.09% 93.47%,
    rgba(98, 72, 255, 0.05) 0%,
    rgba(229, 255, 72, 0.1) 36.93%,
    rgba(255, 72, 237, 0.1) 58.88%,
    rgba(72, 189, 255, 0.1) 70.83%,
    rgba(236, 242, 255, 0.5) 93.23%
  );
}

@keyframes gradient-scale {
  0% {
    transform: scale(1.5) translateZ(0);
  }
  100% {
    transform: scale(1) translateZ(0);
  }
}
</style>
