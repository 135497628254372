bonuses:
  FEE_COMPENSATION: Náhrada poplatku
  FUND_TRANSFER: Vklad
  FUND_TRANSFER_FEE: Poplatek za převod prostředků
  MATCHING_BONUS: Matching bonus
  PARTNER_TURNOVER: Unilevel bonus
  PAYMENT: Platba
  PRODUCT_PURCHASE: Nákup produktu
  PRODUCT_PURCHASE_FEE: Nákup produktu
  PROFIT: Zisk
  PROFIT_LOCKED: Zisk
  REWARD: Odměna
  TOKEN_EXCHANGE: Výměna tokenů
  UNILEVEL_BONUS: Unilevel bonus
  WALLET_TRANSFER: Výměna tokenů
  WITHDRAW: Výběr
  WITHDRAW_FEE: Poplatek za výběr
button:
  about: Onás
  addressCopied: Odkaz byl zkopírován
  back: Zpět
  cancel: Vymazání
  changeAvatar: Změňte si avatara
  close: Uzavření
  configureTotp: Nastavení 2FA
  confirm: Potvrď
  copied: Odkaz byl zkopírován
  copyReflink: Zkopírujte odkaz na zprostředkování
  createAccount: Vytvořit účet
  deposit: Nákup
  download: Stáhnout ▼
  go: Může jít
  home: Zpět
  joinWaitlist: Začněme cestu!
  logIn: Přihlásit se
  logOut: Odhlásit se
  saveChanges: Uložit změnu
  submit: Odesílání
  toggle_dark: Tmavý režim
  toggle_langs: Změnit jazyk
  updatePassword: Aktualizovat heslo
confirmation:
  subtitle: 'Pro potvrzení akce zadejte kód, který jsme vám právě zaslali e-mailem.'
  title: Potvrďte akci
dialog:
  confirmation:
    title: Potvrzení
  deposit:
    insufficientFunds: Nedostatek prostředků
    title: Doplňte zůstatek
fastStart:
  REFERRALLINK:
    title: Najděte svůj doporučující odkaz
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: Začátek
  TLCANNOUNCEMENTS:
    title: Najděte aktualizace TLC
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: První vklad
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: Dostat podporu
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: Získejte balíček
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: Získejte marketing pdf
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: Podívejte se na svůj tým
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: Vybrat finanční prostředky
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: Výsledky obchodování
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2024
forms:
  amount:
    label: Suma
  amountMax:
    label: Částka (max)
  avatar:
    edited: Avatar se změnil
    label: Avatar
  balance:
    label: Zůstatek
  bedType:
    label: Špatný tip
  code:
    label: Kód
  country:
    label: Země
  deposit:
    label: Nákup
  description:
    label: Popis
  email:
    exists: Tento e-mail již existuje
    invalid: Tato e-mailová adresa je neplatná
    label: E-mail
    labelRegistration: Vaše emailová adresa
    notExists: Ten email neexistuje
    required: Vyžaduje se e-mailová adresa
  emailOrNickname:
    label: Email nebo přezdívka
  enabled:
    label: Povolen
  endDate:
    label: Datum ukončení
  endsAt:
    label: Končí to
  endsIn:
    label: Očekávané trvání
  errors:
    alphanumeric: Povolena jsou pouze písmena a číslice
    dateAfterNow: Datum musí být v budoucnosti
    number:
      positive: Hodnota musí být větší než 0
    required: Toto pole je povinné
    startDateAfterEndDate: Datum začátku musí být dřívější než datum ukončení
  expiryDate:
    label: Datum spotřeby
  field:
    label: Louka
  firstName:
    label: Křestní jméno
    placeholder: John
  fullName:
    label: Celé jméno
    placeholder: Jano Doe
  id:
    label: ID
  lastName:
    label: Příjmení
    placeholder: Doe
  name:
    label: Jméno
    placeholder: Jméno
  nationalNumber:
    label: Identifikátor země
  newPassword:
    label: Nové heslo
  newPasswordRepeat:
    label: Opět nové heslo
  nickname:
    exists: Tuto přezdívku už někdo používá
    label: Přezdívka
  oldPassword:
    label: Staré heslo
  password:
    error: Hesla se neshodují
    label: 'Heslo  '
  passwordRepeat:
    label: Zopakujte heslo
  phoneNumber:
    errors:
      invalid: Neplatné telefonní číslo
    label: Telefonní číslo
  phonePrefix:
    label: Předvolba
  postalCode:
    label: Poštovní směrovací číslo
    placeholder: Poštovní směrovací číslo
  price:
    label: cena
  privacyPolicy:
    label: 'Souhlasím s {policy} a {tc}'
    policy:
      label: Zásady ochrany osobních údajů
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: Pravidla a podmínky
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: Probíhá
  quantity:
    label: Množství
  query:
    label: Dotaz
    notExists: Zadaný uživatel neexistuje
  question:
    label: Otázka
    placeholder: Zde zadejte svůj dotaz
  remarks:
    label: Komentujte
  response:
    label: Odpověď
  secondName:
    label: Druhé křestní jméno
  status:
    active:
      label: Aktivní
    label: Postavení
    notActive:
      label: Neaktivní
  timezone:
    label: Časové pásmo
  title:
    label: Titul
  type:
    label: Typ
  value:
    label: Hodnota
global:
  daysShort: den
  hoursShort: Hodiny
  minutesShort: měsíc
  people: Osoba
history:
  fields:
    date: Datum
    description: Popis
    id: Identifikace
    price: cena
  filter:
    byBonus: Filtrujte podle bonusu
  type:
    PAYMENT: Platba
    PRODUCT_PURCHASED: Zakoupený produkt
    SALES_COMMISSION: Prodejní provize
    WITHDRAW: Odčítání
home:
  commission:
    currentLevel: Aktuální hodnost
    deposit: Záloha
    getLink: Vytvořte reflink
    requestCard: ''
    title: Peněženka
    transfer: Převod
    withdraw: Výběr
    yourSponsor: Sponzor
  sales:
    activeReferrals: Aktivní členové týmu
    title: Statistiky prodeje
    total: Celková provize USD
  statistics:
    of: '{0} z {1}'
    title: Shrnutí
  transactions:
    title: Historie transakcí
  wallets:
    DEPOSIT:
      title: Vkladová peněženka
    title: Zůstatek
    transfer:
      subtitle: Vyberte si preferovanou peněženku
      title: Preferovaná peněženka na výběr zůstatku
    WITHDRAW:
      title: Výběrová peněženka
homeStats:
  direct: Přímý obrat
  directReferrals: Přímí členové týmu
  team: Člen týmu
  volume: Týmový obrat
marketing:
  presentation:
    cta: Stáhněte si prezentaci
    subtitle: Jazyk prezentace
    title: Stáhněte si prezentaci TLC
  title: Marketingový materiál
menu:
  blog: Zprávy
  bonuses: Transakce
  contest: Leader propagace
  copyReflink: Kopírovat odkaz
  dashboard: Ovládací panel
  fastStart: Začátek
  history: Historia provizí
  knowledgeBase: Úložiště znalostí
  logOut: Odhlásit se
  marketing: Marketingový materiál
  products: Produkty
  settings: Nastavení
  shareReflink: Sdílet Reflink
  structure: Struktura týmu
  support: Podpora
  teamCenter: Týmové centrum
  traders: Obchodníci
  you: Vy
navigation:
  title:
    blog: Zprávy
    confirmation: Potvrzení
    dashboard: Ovládací panel
    fastStart: Rychlý start
    marketing: Marketingový materiál
    packages: Balíčky
    settings: nastavení
    statistics: Historie transakcí
    structure: Struktura týmu
    support: Podpora
    teamCenter: Týmové centrum
    traders: Přehled obchodu
not-found: Nenalezeno
ownedProducts:
  title: Vlastní balíčky
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: Vklad
    days: dní
    infoAmount: 'Umožní vám koupit balíček v hodnotě ${amount}.'
    payTo: >-
      Odešlete prostředky na následující adresu USD TRC20. <br> Platba zahrnuje
      poplatek 2$.
    redirect: Brzy budete přesměrováni
    warning: >-
      Pošlete prosím přesně stejnou částku, jakou ukazuje váš platební systém po
      kliknutí na vklad.
  confirmation:
    text: Jsi si jistý?
  summerPromo:
    button: Pokračování
    text: >-
      Vzhledem k letním prázdninám připravujeme s extra slevami! Měsíční
      předplatné je nyní 119 USD a dvouměsíční 199 USD
    title: Letní akce!
  transfer:
    address: Adresa
    addressLabel: Adresa Tron
    amount: Suma
    amountLabel: množstvíLabel
    button: Převod
    status: Postavení
    success: Úspěšný převod
    text: >-
      Chcete-li převést prostředky na jiného uživatele, vyplňte formulář.<br> Z
      vaší peněženky bude stržen poplatek ve výši 1 %.
    title: Převod
  withdraw:
    address: Adresa
    addressLabel: Adresa Tron
    amount: Suma
    amountLabel: množstvíLabel
    button: Výběr
    status: Postavení
    success: Výběr iniciován
    text: >-
      Zadejte prosím síťovou adresu tron, na kterou si přejete přijímat převod
      USDT.
    title: 'Zadejte adresu sítě tron, na kterou chcete přijmout převod USDT.'
  withdrawSummary:
    title: Stáhnout souhrn
products:
  inputPrice: 'Chcete-li pokračovat, vyberte balíček'
  owned:
    limit: Disponibilní zůstatek
    none: Nemáte vlastní balíček
    title: Vlastní balíčky
  timeToEnd: Čas do vypršení platnosti
  title: Pořiďte si balíček
settings:
  authentication:
    title: Identifikace
  personal:
    title: Osobní údaje
  profile:
    cta: Nové nahrání
    language: 'Jazyk  '
    subtitle: Jak to vidí jiný uživatel
    title: Váš avatar
  sponsor:
    title: Tvůj sponzor
  telegram:
    title: Telegramové připojení
signup:
  alreadyRegistered: Již zaregistrován?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: Staňte se součástí obchodní revoluce
    title: Úplně první obchodní AI
  privacy: Zásady ochrany osobních údajů
  regulations: Souhlasím s pravidly a podmínkami TLC
  subtitle: >-
    {nickname} mě pozval do projektu. Chcete-li pokračovat, vyplňte formulář
    níže.
  terms: Podmínky používání
  title: Přidat se teď!
statistics:
  max: Max
  sum: Suma
  sumDay: Dnešní částka
  sumMonth: Měsíční suma
structure:
  activeUntil: 'Zatím aktivní:'
  copyReflink: Kopírovat Reflink
  directReferrals: 'Přímý tým '
  inactive: Neaktivní
  invested: Uloženo
  joined: Připojeno
  referrals: Doporučení
  search: Vyhledejte osobu
  showDetails: Zobrazit podrobnosti
  title: Struktura zprostředkování
  totalPackages: Celkový počet balíků
  turnover: Obrat
support:
  alreadyRegistered: Už zaregistrován?
  footer:
    subtitle: >-
      Máte-li jakékoli problémy s produkty a chcete od nás získat pomoc, pošlete
      nám e-mail na adresu support{'@'}tradelikecrazy.ai.
    title: Všeobecná žádost o pomoc
  header:
    subtitle: >-
      Máte-li jakékoli problémy s produkty a chcete od nás získat pomoc, pošlete
      nám e-mail na adresu support{'@'}tradelikecrazy.ai.
    title: Technický problém
  privacy: Zásady ochrany osobních údajů
  subtitle: K odeslání lístku vyplňte formulář
  terms: Podmínky používání
  title: Podání lístku
table:
  headers:
    action: Akce
    name: název
    terminationStartDate: Datum začátku
  referralTree:
    deposit: Záloha
    direct: Přímý prodej
    level: úroveň
    referrals: Doporučení
    turnover: Obrat
  staking:
    marketCap: Tržní kapitalizace
  trades:
    attachment: Foto důkaz
    detail: Detail
    entryDate: Vložte datum
    entryPrice: Cena vstupného
    exitDate: Datum odjezdu
    exitPrice: Výstupní cena
    margin: Zisk
    pair: Pár
    profit: Zisk
    type: Typ
  transactions:
    amount: Typ/množství
    date: Datum Čas
    id: ID transakce
    type: Typ
teamCenter:
  referralTree:
    title: Přehled struktury
toasts:
  success:
    general: Úspěšná transakce!
traders:
  feedTitle: Příběh obchodování
  title: Obchodníci
