<script setup lang="ts">
const props = defineProps({
  type: {
    type: String as PropType<'button' | 'submit' | 'reset' | undefined>,
    default: 'button',
  },
  icon: {
    type: Boolean,
    default: false,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  text: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  block: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  verySmall: {
    type: Boolean,
    default: false,
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  to: {
    type: String,
    default: '',
  },
  leftSlotClass: {
    type: String,
    default: '',
  },
  contentClass: {
    type: String,
    default: '',
  },
  href: {
    type: String,
    default: '',
  },
})

const tag = computed(() => {
  if (props.to) {
    return 'router-link'
  }

  if (props.href) {
    return 'a'
  }

  return 'button'
})
</script>

<template>
  <component
    :is="tag"
    ref="buttonRef"
    class="app-button card-xl"
    :class="[
      props.block ? 'flex w-full' : 'inline-flex',
      {
        'has-error': props.error,
        'has-success': props.success,
        'is-disabled': props.disabled,
        'is-secondary': props.secondary,
        'is-text': props.text,
        'is-icon': props.icon,
        'is-small': props.small,
        'is-very-small': props.verySmall,
      },
    ]"
    :type="props.type"
    :disabled="props.disabled"
    :to="props.to"
    :href="props.href"
  >
    <div
      :class="[
        `button__content
        relative
        flex flex-nowrap
        justify-center
        items-center`,
        props.contentClass,
      ]"
    >
      <div
        v-if="!props.icon && $slots.left"
        :class="['button__left mr-2 relative', props.leftSlotClass]"
      >
        <slot name="left"></slot>
      </div>
      <div class="button__center relative flex flex-nowrap">
        <slot v-if="!(props.icon && props.loading)"></slot>
        <div
          v-show="props.icon && props.loading"
          class="loader-wrap flex flex-nowrap transition-opacity duration-200"
        >
          <div class="loader__icon animate-spin">
            <AppSvgIcon name="icon_refresh" color="white" />
          </div>
        </div>
      </div>
      <div
        v-if="!props.icon && ($slots.right || props.loading)"
        class="button__right ml-2 relative"
      >
        <div
          v-if="props.loading"
          class="loader-wrap flex flex-nowrap transition-opacity duration-200"
        >
          <div class="loader__icon animate-spin">
            <AppSvgIcon name="icon_refresh" color="white" />
          </div>
        </div>
        <slot v-else name="right"></slot>
      </div>
    </div>
  </component>
</template>

<style lang="css" scoped>
.app-button {
  @apply relative
      justify-center
      items-center
      font-semibold
      bg-primary-default
      py-3 sm:py-5
      uppercase
      px-7.5
      leading-[1.38]
      tracking-[0.023rem]
      text-sm
      text-theme-white
      transition
      duration-400
      transition-bg transition-bg-duration-200
      bg-center
      overflow-hidden
      border-solid border-transparent border-2
      hover:bg-primary
      disabled:cursor-not-allowed
      rounded-2xl;

  -webkit-appearance: none;

  &.is-small {
    @apply py-3 px-5;
  }
   &.is-very-small {
    @apply py-1 px-3  !text-xs;
  }

  &:focus,
  &:hover {
    @apply bg-[#467aff];
  }

  &.is-disabled,
  &.is-disabled:hover {
    @apply bg-none bg-gray-200 text-gray-400;
  }

  &.has-success {
    @apply bg-green-500 text-white;
  }

  &.has-error {
    @apply bg-red-600 text-white;
  }

  &.is-secondary {
    @apply bg-primary-light-darker text-primary-default;

    &:hover,
    &:focus {
      @apply bg-[#b6cffb];
    }

    &.is-disabled,
    &.is-disabled:hover {
      @apply text-gray-400 bg-gray-200;
    }

    &.has-success {
      @apply bg-green-500;
    }

    &.has-error {
      @apply border-red-600;
    }
  }

  &.is-text {
    @apply !bg-transparent text-white transition-opacity !opacity-80;

    &:hover,
    &:focus {
      @apply text-white !opacity-100;
    }

    &:focus {
      outline: none;
    }

    &.is-disabled {
      @apply text-gray-200;
    }

    &.has-success {
      @apply text-green-500;
    }

    &.has-error {
      @apply text-red-600;
    }
  }

  &.is-icon {
    @apply p-0;

    height: 48px;
    width: 48px;
  }
}
</style>

<style lang="postcss">
.app-button {
  .ripple {
    @apply bg-secondary text-white;

    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
  }

  .app-svg-icon {
    use {
      @apply fill-white;
    }
  }

  &.router-link-exact-active {
    @apply !bg-primary;
  }

  &.is-secondary {
    .app-svg-icon {
      use {
        @apply fill-dark-900;
      }
    }

    &:hover {
      .app-svg-icon {
        use {
          @apply fill-white;
        }
      }
    }
  }

  &.is-icon {
    &.is-secondary,
    &.is-text {
      .app-svg-icon {
        use {
          @apply fill-secondary;
        }
      }

      &.has-success {
        .app-svg-icon {
          use {
            @apply fill-green-500;
          }
        }
      }

      &.has-error {
        .app-svg-icon {
          use {
            @apply fill-red-600;
          }
        }
      }
    }

    &.is-secondary {
      &.is-disabled {
        .app-svg-icon {
          use {
            @apply fill-gray-200;
          }
        }
      }
    }

    &.is-subtle {
      &.is-disabled {
        .app-svg-icon {
          use {
            @apply fill-gray-100;
          }
        }
      }
    }
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
</style>
