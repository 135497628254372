bonuses:
  FEE_COMPENSATION: Компенсация гонорара
  FUND_TRANSFER: Перевод средств
  FUND_TRANSFER_FEE: Комиссия за перевод средств
  MATCHING_BONUS: Матчинг бонус
  PARTNER_TURNOVER: Юнилевл бонус
  PAYMENT: Депозит
  PRODUCT_PURCHASE: Покупка продукта
  PRODUCT_PURCHASE_FEE: Покупка продукта
  PROFIT: Прибыль с пакетов
  PROFIT_LOCKED: Прибыль
  REWARD: Награды
  TOKEN_EXCHANGE: Обмен токенов
  UNILEVEL_BONUS: Юнилевел бонус
  WALLET_TRANSFER: Обмен токенов
  WITHDRAW: Вывод средств
  WITHDRAW_FEE: Комиссия за вывод средств
button:
  about: Про нас
  addressCopied: Ссылка скопирована
  back: Назад
  cancel: Отмена
  changeAvatar: Сменить аватар
  close: Закрыть
  configureTotp: Включить 2-х факторную аутентификацию
  confirm: Подтвердите
  copied: Ссылка скопирована
  copyReflink: Cкопировать реферальную ссылку
  createAccount: Создать аккаунт
  deposit: Купить
  download: Скачать
  go: Перейти
  home: Главная
  joinWaitlist: Начните путь!
  logIn: Войти
  logOut: 'Выйти '
  saveChanges: Сохранить изменения
  submit: Отправить
  toggle_dark: Включить темный режим
  toggle_langs: Смена языков
  updatePassword: Обновить пароль
confirmation:
  subtitle: >-
    Введите код, который мы только что отправили вам по электронной почте, чтобы
    подтвердить действие.
  title: Подтвердить действие
dialog:
  confirmation:
    title: Подтверждение
  deposit:
    insufficientFunds: 'Недостаточно средств. Пожалуйста, сначала внесите депозит.'
    title: Депозитные средства
  requestCard:
    preferredType: ''
    subtitle: ''
    title: ''
fastStart:
  REFERRALLINK:
    title: Найдите свою реферальную ссылку
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: Начало работы
  TLCANNOUNCEMENTS:
    title: Найдите обновления TLC
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: Первый депозит
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: Получать поддержку
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: Получить пакет
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: Получить маркетинговый PDF-файл
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: Увидеть свою команду
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: Снять Средства
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: Результаты торгов
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
forms:
  amount:
    label: Сумма
  amountMax:
    label: 'Сумма (Максимальная ${max})'
  avatar:
    edited: Аватар был изменен
    label: Аватар
  balance:
    label: Баланс
  bedType:
    label: Тип кровати
  code:
    label: Код
  country:
    label: Страна
  deposit:
    label: Купить
  description:
    label: Описание
  email:
    exists: Этот адрес электронной почты уже использовался.
    invalid: Этот адрес электронной почты недействителен.
    label: Электронная почта
    labelRegistration: Ваш адрес электронной почты
    notExists: Этой электронной почты не существует.
    required: Адрес электронной почты является обязательным
  emailOrNickname:
    label: Электронная почта или псевдоним
  enabled:
    label: Включен
  endDate:
    label: Дата окончания
  endsAt:
    label: Заканчивается в
  endsIn:
    label: Предполагаемая продолжительность
  errors:
    alphanumeric: Разрешены только буквы и цифры
    dateAfterNow: Дата должна быть в будущем
    number:
      positive: Значение должно быть больше 0
    required: Это поле обязательно для заполнения
    startDateAfterEndDate: Дата начала должна быть раньше даты окончания
  expiryDate:
    label: Срок действия
  field:
    label: Поле
  firstName:
    label: Имя
    placeholder: Джон
  fullName:
    label: Полное имя
    placeholder: Джон Доу
  id:
    label: ID
  lastName:
    label: Фамилия
    placeholder: Доу
  name:
    label: Имя
    placeholder: Имя
  nationalNumber:
    label: Номер страны
  newPassword:
    label: Новый пароль
  newPasswordRepeat:
    label: Повторите новый пароль
  nickname:
    exists: Это прозвище уже использовалось
    label: Псевдоним
  oldPassword:
    label: Старый пароль
  password:
    error: Пароли не совпадают
    label: Пароль
  passwordRepeat:
    label: Повторите пароль
  phoneNumber:
    errors:
      invalid: Неверный номер телефона
    label: Номер телефона
  phonePrefix:
    label: Префикс
  postalCode:
    label: Почтовый индекс
    placeholder: Почтовый индекс
  price:
    label: Цена
  privacyPolicy:
    label: 'Я согласен с {policy} и {tc}'
    policy:
      label: Политика конфиденциальности
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: Условия и положения
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: Прогресс
  quantity:
    label: Количество
  query:
    label: Запрос
    notExists: Данного пользователя не существует.
  question:
    label: Вопрос
    placeholder: Введите свой вопрос здесь
  remarks:
    label: Примечания
  response:
    label: Ответ
  secondName:
    label: Второе имя
  status:
    active:
      label: Активный
    label: Статус
    notActive:
      label: Не активен
  timezone:
    label: Часовой пояс
  title:
    label: Название
  type:
    label: Вид
  value:
    label: Значение
global:
  daysShort: День
  hoursShort: Час
  minutesShort: Месяц
  people: Человек | Люди
history:
  fields:
    date: ДАТА
    description: ОПИСАНИЕ
    id: ID
    price: ЦЕНА
  filter:
    byBonus: Фильтр по бонусам
  type:
    PAYMENT: ОПЛАТА
    PRODUCT_PURCHASED: КУПЛЕННЫЙ ПРОДУКТ
    SALES_COMMISSION: КОМИССИЯ С ПРОДАЖ
    WITHDRAW: Вывод
home:
  commission:
    currentLevel: Ваш текущий ранг
    deposit: Депозит
    getLink: Сгенерировать реферальную ссылку
    requestCard: ''
    title: Ваш кошелек
    transfer: Перевод
    withdraw: 'Вывод '
    yourSponsor: 'Ваш спонсор: {sponsor}'
  sales:
    activeReferrals: 'Активные рефералы: {0}'
    title: Статистика продаж
    total: 'Общая комиссия: {0} USD'
  statistics:
    of: '{0} из {1}'
    title: Сводка
  transactions:
    title: История транзакций
  wallets:
    DEPOSIT:
      title: Кошелек для депозитов
    title: Балансы
    transfer:
      subtitle: Выберите желаемый кошелек
      title: Предпочтительный кошелек для вывода средств
    WITHDRAW:
      title: Кошелек для вывода
homeStats:
  direct: Прямой оборот
  directReferrals: Прямые рефералы
  team: Члены команды
  volume: Оборот команды
marketing:
  presentation:
    cta: Получить презентацию
    subtitle: Язык презентации
    title: Скачать презентацию TLC
  title: Маркетинг
menu:
  blog: Объявления
  bonuses: Транзакции
  contest: Продвижение лидера
  copyReflink: Скопировать реферальную ссылку
  dashboard: Информационная панель
  fastStart: Начало работы
  history: История комиссии
  knowledgeBase: База знаний
  logOut: Выйти
  marketing: Маркетинг
  products: Продукты
  settings: Настройки
  shareReflink: Поделиться ссылкой
  structure: Ваша структура
  support: Поддержка
  teamCenter: Командный центр
  traders: Трейдеры
  you: Вы
navigation:
  title:
    blog: Объявления
    confirmation: Подтверждение
    dashboard: Ваша информационная панель
    fastStart: Быстрый старт
    marketing: Маркетинг
    packages: пакеты
    settings: настройки
    statistics: История транзакций
    structure: Ваша структура
    support: Поддержка
    teamCenter: Командный центр
    traders: Обзор трейдинга
not-found: Не найдено
ownedProducts:
  title: Имеющиеся пакеты
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: Депозит
    days: Дни
    infoAmount: 'Это позволит вам купить пакет стоимостью ${amount} долларов США. '
    payTo: >-
      Пожалуйста, отправьте средства на следующий адрес USD TRC20. <br> Платеж
      включает комиссию в размере 2$.
    redirect: Вы будете перенаправлены в ближайшее время
    warning: >-
      Пожалуйста, отправьте именно ту сумму, которая указана в вашей платежной
      системе после нажатия кнопки "Депозит".
  confirmation:
    text: Вы уверены?
  summerPromo:
    button: Продолжить
    text: >-
      В связи с летними каникулами мы подготовили дополнительные скидки!
      Месячная подписка теперь стоит 119$, а двухмесячная - 199$
    title: Летняя акция!
  transfer:
    address: Адрес
    addressLabel: Адрес Tron
    amount: Сумма
    amountLabel: Сумма
    button: Перевод
    status: Статус
    success: Трансфер прошел успешно.
    text: >-
      Чтобы перевести средства другому пользователю, заполните форму.<br>
      Комиссия в размере 1% будет снята с вашего кошелька.
    title: Перевод средств
  withdraw:
    address: Адрес
    addressLabel: Адрес Tron
    amount: Сумма
    amountLabel: Сумма
    button: Вывод
    status: Статус
    success: Вывод средств начат
    text: 'Введите адрес сети tron, на который вы хотите получить перевод USDT.'
    title: Вывести средства
  withdrawSummary:
    title: Сводка выплаты
products:
  inputPrice: 'Выберите стоимость пакета ниже, чтобы продолжить.'
  owned:
    limit: 'Доступный лимит:'
    none: Вы еще не владеете ни одним пакетом.
    title: Активные пакеты
  timeToEnd: Время заканчивать
  title: Приобрести пакет
settings:
  authentication:
    title: Аутентификация
  personal:
    title: Личная информация
  profile:
    cta: Загрузите новый
    language: Язык
    subtitle: Как вас видит другой пользователь
    title: Ваш аватар
  sponsor:
    title: Ваш спонсор
  telegram:
    title: Подключение Telegram
signup:
  alreadyRegistered: Уже зарегистрированы?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: Станьте частью революции в трейдинге.
    title: Первый в мире трейдинговый ИИ
  privacy: Политика конфиденциальности
  regulations: ' "Я согласен с {terms} и {privacy} TradeLikeCrazy.ai'
  subtitle: >-
    Вы получили приглашение от {nickname} на проект. Заполните форму ниже, чтобы
    продолжить.
  terms: Условия и требования
  title: Присоединяйтесь к нам!
statistics:
  max: Максимум
  sum: Сумма
  sumDay: Сегодняшняя сумма
  sumMonth: Сумма за месяц
structure:
  activeUntil: Активен до
  copyReflink: Скопировать реферальную ссылку
  directReferrals: Прямые
  inactive: Неактивный
  invested: Вложенный депозит
  joined: Присоединился
  referrals: Рефералы
  search: Найти партнера
  showDetails: Показать детали
  title: Дерево рефералов
  totalPackages: Всего пакетов
  turnover: Оборот
support:
  alreadyRegistered: Уже зарегистрированы?
  footer:
    subtitle: >-
      Если у вас возникли какие-либо проблемы с продукцией и вы хотите получить
      от нас помощь, пожалуйста, напишите нам по адресу
      support{'@'}tradelikecrazy.ai
    title: Общая поддержка
  header:
    subtitle: >-
      Если у вас возникли какие-либо технические проблемы и вы хотите получить
      от нас помощь, пожалуйста, напишите нам по адресу
      support{'@'}tradelikecrazy.ai
    title: Техническая проблема
  privacy: Политика конфиденциальности
  subtitle: >-
    Опишите вашу проблему наша техническая команда займется ее решением в
    максимально короткие сроки.
  terms: Условия и требования
  title: Заполните форму
table:
  headers:
    action: Действие
    name: Имя
    terminationStartDate: Дата начала
  referralTree:
    deposit: Депозит
    direct: Прямые продажи
    level: Уровень
    referrals: Рефералы
    turnover: Оборот
  staking:
    marketCap: Рыночная капитализация
  trades:
    attachment: Фотодоказательство
    detail: Деталь
    entryDate: Дата входа
    entryPrice: Цена входа
    exitDate: Дата выхода
    exitPrice: Цена выхода
    margin: Маржа
    pair: Пара
    profit: Прибыль
    type: Вид
  transactions:
    amount: Тип/сумма
    date: Дата/время
    id: ID транзакции
    type: Вид
teamCenter:
  referralTree:
    title: Обзор структуры
toasts:
  success:
    general: Успех!
traders:
  feedTitle: История торговли
  title: Трейдеры
