bonuses:
  FEE_COMPENSATION: การชดเชยค่าธรรมเนียม
  FUND_TRANSFER: การโอนเงิน
  FUND_TRANSFER_FEE: ค่าธรรมเนียมการโอนเงิน
  MATCHING_BONUS: โบนัสการจับคู่
  PARTNER_TURNOVER: โบนัสระดับเดียว
  PAYMENT: การชำระเงิน
  PRODUCT_PURCHASE: ซื้อสินค้า
  PRODUCT_PURCHASE_FEE: ซื้อสินค้า
  PROFIT: กำไร
  PROFIT_LOCKED: กำไร
  REWARD: รางวัล
  TOKEN_EXCHANGE: การแลกเปลี่ยนโทเค็น
  UNILEVEL_BONUS: โบนัสระดับเดียว
  WALLET_TRANSFER: การแลกเปลี่ยนโทเค็น
  WITHDRAW: ถอน
  WITHDRAW_FEE: ค่าธรรมเนียมการถอน
button:
  about: เกี่ยวกับ
  addressCopied: คัดลอกลิงก์แล้ว
  back: กลับ
  cancel: ยกเลิก
  changeAvatar: เปลี่ยนอวตาร
  close: ปิด
  configureTotp: ชุด 2FA
  confirm: ยืนยัน
  copied: คัดลอกลิงก์แล้ว
  copyReflink: คัดลอกลิงก์อ้างอิง
  createAccount: สร้างบัญชี
  deposit: ซื้อ
  download: ดาวน์โหลด
  go: ไป
  home: บ้าน
  joinWaitlist: เริ่มต้นการเดินทาง!
  logIn: เข้าสู่ระบบ
  logOut: ออกจากระบบ
  saveChanges: บันทึกการเปลี่ยนแปลง
  submit: ส่ง
  toggle_dark: สลับโหมดมืด
  toggle_langs: เปลี่ยนภาษา
  updatePassword: อัปเดตรหัสผ่าน
confirmation:
  subtitle: ป้อนรหัสที่เราเพิ่งส่งอีเมลถึงคุณเพื่อยืนยันการดำเนินการ
  title: ยืนยันการดำเนินการ
dialog:
  confirmation:
    title: การยืนยัน
  deposit:
    insufficientFunds: เงินทุนไม่เพียงพอ. กรุณาทำการฝากเงินก่อน
    title: ฝากเงิน
  requestCard:
    preferredType: ''
    subtitle: ''
    title: ''
fastStart:
  REFERRALLINK:
    title: ค้นหาลิงค์ผู้อ้างอิงของคุณ
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: เริ่มต้นใช้งาน
  TLCANNOUNCEMENTS:
    title: ค้นหาการอัปเดต tlc
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: ฝากครั้งแรก
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: ได้รับการสนับสนุน
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: รับแพ็คเกจ
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: รับ pdf การตลาด
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: ดูทีมของคุณ
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: ถอนเงิน
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: ผลการซื้อขาย
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
forms:
  amount:
    label: จำนวน
  amountMax:
    label: 'จำนวนเงิน(สูงสุด ${max})'
  avatar:
    edited: อวาตาร์ได้ถูกเปลี่ยนแล้ว
    label: สัญลักษณ์
  balance:
    label: สมดุล
  bedType:
    label: ประเภทเตียง
  code:
    label: รหัส
  country:
    label: ประเทศ
  deposit:
    label: ซื้อ
  description:
    label: คำอธิบาย
  email:
    exists: อีเมลนี้ถูกใช้ไปแล้ว
    invalid: อีเมลนี้ไม่ถูกต้อง
    label: อีเมล
    labelRegistration: ที่อยู่อีเมลของคุณ
    notExists: ไม่มีอีเมลนี้
    required: ที่อยู่อีเมลเป็นสิ่งจำเป็น
  emailOrNickname:
    label: อีเมลหรือชื่อเล่น
  enabled:
    label: เปิดใช้งานแล้ว
  endDate:
    label: วันที่สิ้นสุด
  endsAt:
    label: สิ้นสุดที่
  endsIn:
    label: ระยะเวลาโดยประมาณ
  errors:
    alphanumeric: อนุญาตให้ใช้เฉพาะตัวอักษรและตัวเลขเท่านั้น
    dateAfterNow: วันที่จะต้องอยู่ในอนาคต
    number:
      positive: ค่าต้องมากกว่า 0
    required: ต้องระบุข้อมูลในช่องนี้
    startDateAfterEndDate: วันที่เริ่มต้นจะต้องอยู่ก่อนวันที่สิ้นสุด
  expiryDate:
    label: วันหมดอายุ
  field:
    label: สนาม
  firstName:
    label: ชื่อจริง
    placeholder: จอห์น
  fullName:
    label: ชื่อเต็ม
    placeholder: จอห์น โด
  id:
    label: บัตรประจำตัวประชาชน
  lastName:
    label: นามสกุล
    placeholder: โด้
  name:
    label: ชื่อ
    placeholder: ชื่อ
  nationalNumber:
    label: หมายเลขประจำชาติ
  newPassword:
    label: รหัสผ่านใหม่
  newPasswordRepeat:
    label: ทำซ้ำรหัสผ่านใหม่
  nickname:
    exists: ชื่อเล่นนี้ถูกใช้ไปแล้ว
    label: ชื่อเล่น
  oldPassword:
    label: รหัสผ่านเก่า
  password:
    error: รหัสผ่านไม่ตรงกัน
    label: รหัสผ่าน
  passwordRepeat:
    label: ทำซ้ำรหัสผ่าน
  phoneNumber:
    errors:
      invalid: หมายเลขโทรศัพท์ไม่ถูกต้อง
    label: หมายเลขโทรศัพท์
  phonePrefix:
    label: คำนำหน้า
  postalCode:
    label: รหัสไปรษณีย์
    placeholder: รหัสไปรษณีย์
  price:
    label: ราคา
  privacyPolicy:
    label: 'ฉันเห็นด้วยกับ {policy}'
    policy:
      label: นโยบายความเป็นส่วนตัว
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: ข้อกำหนดและเงื่อนไข
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: ความคืบหน้า
  quantity:
    label: ปริมาณ
  query:
    label: แบบสอบถาม
    notExists: ไม่มีผู้ใช้ที่ระบุ
  question:
    label: คำถาม
    placeholder: ป้อนคำถามของคุณที่นี่
  remarks:
    label: หมายเหตุ
  response:
    label: การตอบสนอง
  secondName:
    label: ชื่อที่สอง
  status:
    active:
      label: คล่องแคล่ว
    label: สถานะ
    notActive:
      label: ไม่ทำงาน
  timezone:
    label: เขตเวลา
  title:
    label: ชื่อ
  type:
    label: พิมพ์
  value:
    label: ค่า
global:
  daysShort: วว
  hoursShort: ฮฮ
  minutesShort: มม
  people: บุคคล | ประชากร
history:
  fields:
    date: วันที่
    description: คำอธิบาย
    id: บัตรประจำตัวประชาชน
    price: ราคา
  filter:
    byBonus: กรองตามโบนัส
  type:
    PAYMENT: การชำระเงิน
    PRODUCT_PURCHASED: สินค้าที่ซื้อ
    SALES_COMMISSION: คณะกรรมการการขาย
    WITHDRAW: ถอน
home:
  commission:
    currentLevel: อันดับปัจจุบันของคุณ
    deposit: เงินฝาก
    getLink: สร้างการอ้างอิง
    requestCard: ''
    title: กระเป๋าเงินของคุณ
    transfer: โอนย้าย
    withdraw: ถอน
    yourSponsor: 'สปอนเซอร์ของคุณคือ: {sponsor}'
  sales:
    activeReferrals: 'การอ้างอิงที่ใช้งานอยู่: {0}'
    title: สถิติการขาย
    total: 'ค่าคอมมิชชันทั้งหมด: {0} USD'
  statistics:
    of: '{0} จาก {1}'
    title: สรุป
  transactions:
    title: ประวัติการทำรายการ
  wallets:
    DEPOSIT:
      title: ฝากกระเป๋าเงิน
    title: ยอดคงเหลือ
    transfer:
      subtitle: เลือกกระเป๋าสตางค์ที่ต้องการ
      title: กระเป๋าเงินที่ต้องการรับเงินจาก
    WITHDRAW:
      title: ถอนกระเป๋าสตางค์
homeStats:
  direct: การหมุนเวียนโดยตรง
  directReferrals: การอ้างอิงโดยตรง
  team: สมาชิกในทีม
  volume: การหมุนเวียนของทีม
marketing:
  presentation:
    cta: รับการนำเสนอ
    subtitle: ภาษาการนำเสนอ
    title: ดาวน์โหลดการนำเสนอ TLC
  title: การตลาด
menu:
  blog: ประกาศ
  bonuses: การทำธุรกรรม
  contest: การส่งเสริมความเป็นผู้นำ
  copyReflink: คัดลอกลิงก์อ้างอิง
  dashboard: แผงควบคุม
  fastStart: เริ่มต้นใช้งาน
  history: ประวัติคอมมิชชั่น
  knowledgeBase: ฐานความรู้
  logOut: ออกจากระบบ
  marketing: การตลาด
  products: สินค้า
  settings: การตั้งค่า
  shareReflink: แชร์ลิงก์อ้างอิง
  structure: โครงสร้างของคุณ
  support: สนับสนุน
  teamCenter: ศูนย์ทีม
  traders: เทรดเดอร์
  you: คุณ
navigation:
  title:
    blog: ประกาศ
    confirmation: การยืนยัน
    dashboard: แดชบอร์ดของคุณ
    fastStart: เริ่มต้นอย่างรวดเร็ว
    marketing: การตลาด
    packages: แพคเกจ
    settings: การตั้งค่า
    statistics: ประวัติการทำรายการ
    structure: โครงสร้างของคุณ
    support: สนับสนุน
    teamCenter: ศูนย์ทีม
    traders: ภาพรวมการซื้อขาย
not-found: ไม่พบ
ownedProducts:
  title: แพ็คเกจที่เป็นเจ้าของ
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: เงินฝาก
    days: วัน
    infoAmount: 'จะทำให้คุณสามารถซื้อแพ็คเกจมูลค่า ${amount}'
    payTo: >-
      กรุณาส่งเงินไปยังที่อยู่ USD TRC20 ดังต่อไปนี้ <br>
      การชำระเงินรวมค่าธรรมเนียม 2$
    redirect: คุณจะถูกเปลี่ยนเส้นทางในไม่ช้า
    warning: กรุณาส่งจำนวนเงินเดียวกันกับที่แสดงระบบการชำระเงินหลังจากที่คุณคลิกฝากเงิน
  confirmation:
    text: คุณแน่ใจไหม?
  summerPromo:
    button: ดำเนินการต่อ
    text: >-
      เนื่องจากช่วงวันหยุดฤดูร้อน เราได้เตรียมส่วนลดพิเศษไว้แล้ว!
      การสมัครสมาชิกรายเดือนตอนนี้อยู่ที่ 119$ และแบบสองเดือนอยู่ที่ 199$
    title: โปรโมชั่นรับซัมเมอร์!
  transfer:
    address: ที่อยู่
    addressLabel: ที่อยู่ตรอน
    amount: จำนวน
    amountLabel: amountLabel
    button: โอนย้าย
    status: สถานะ
    success: การโอนสำเร็จแล้ว
    text: >-
      หากต้องการโอนเงินให้กับผู้ใช้รายอื่น โปรดกรอกแบบฟอร์ม<br>
      จะมีการเรียกเก็บค่าธรรมเนียม 1% จากกระเป๋าเงินของคุณ
    title: การโอนเงิน
  withdraw:
    address: ที่อยู่
    addressLabel: ที่อยู่ตรอน
    amount: จำนวน
    amountLabel: amountLabel
    button: ถอน
    status: สถานะ
    success: เริ่มการถอนเงินแล้ว
    text: กรุณากรอกที่อยู่เครือข่ายตรอนที่คุณต้องการรับการโอนเงิน USDT ไป
    title: ถอนเงิน
  withdrawSummary:
    title: ถอนสรุป
products:
  inputPrice: เลือกราคาแพ็คเกจด้านล่างเพื่อดำเนินการต่อ
  owned:
    limit: 'ขีดจำกัดที่ใช้ได้:'
    none: คุณยังไม่มีแพ็คเกจใดๆ
    title: แพ็คเกจที่เป็นเจ้าของ
  timeToEnd: ถึงเวลาสิ้นสุด
  title: ซื้อแพ็คเกจ
settings:
  authentication:
    title: การรับรองความถูกต้อง
  personal:
    title: ข้อมูลส่วนบุคคล
  profile:
    cta: อัพโหลดใหม่
    language: ภาษา
    subtitle: ผู้ใช้รายอื่นเห็นคุณอย่างไร
    title: อวตารของคุณ
  sponsor:
    title: ผู้สนับสนุนของคุณ
  telegram:
    title: การเชื่อมต่อโทรเลข
signup:
  alreadyRegistered: ลงทะเบียนเรียบร้อยแล้ว?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: ร่วมเป็นส่วนหนึ่งของการปฏิวัติการซื้อขาย
    title: การซื้อขาย AI ครั้งแรก
  privacy: นโยบายความเป็นส่วนตัว
  regulations: 'ฉันยอมรับ TradeLikeCrazy.ai {terms} และ {privacy}'
  subtitle: >-
    คุณได้รับเชิญจาก {nickname} ให้เข้าร่วมโครงการ
    กรอกแบบฟอร์มด้านล่างเพื่อดำเนินการต่อ
  terms: ข้อกำหนดและเงื่อนไข
  title: เข้าร่วมกับเราตอนนี้!
statistics:
  max: สูงสุด
  sum: ผลรวม
  sumDay: ยอดวันนี้
  sumMonth: ผลรวมของเดือน
structure:
  activeUntil: ใช้งานได้จนถึง
  copyReflink: คัดลอกลิงก์อ้างอิง
  directReferrals: กำกับ
  inactive: ไม่ได้ใช้งาน
  invested: ฝากแล้ว
  joined: เข้าร่วม
  referrals: การอ้างอิง
  search: ค้นหาบุคคล
  showDetails: แสดงรายละเอียด
  title: ต้นไม้อ้างอิง
  totalPackages: รวมแพ็คเกจ
  turnover: มูลค่าการซื้อขาย
support:
  alreadyRegistered: ลงทะเบียนเรียบร้อยแล้ว?
  footer:
    subtitle: >-
      หากคุณมีปัญหาใดๆ เกี่ยวกับผลิตภัณฑ์และต้องการความช่วยเหลือจากเรา
      โปรดส่งอีเมลไปที่ support{'@'}tradelikecrazy.ai
    title: การสนับสนุนทั่วไป
  header:
    subtitle: >-
      หากคุณมีปัญหาด้านเทคนิคใดๆ และต้องการความช่วยเหลือจากเรา โปรดส่งอีเมลไปที่
      support{'@'}tradelikecrazy.ai
    title: ปัญหาทางเทคนิค
  privacy: นโยบายความเป็นส่วนตัว
  subtitle: กรอกแบบฟอร์มด้านล่างเพื่อส่งตั๋วสนับสนุน
  terms: ข้อกำหนดและเงื่อนไข
  title: ส่งตั๋ว
table:
  headers:
    action: การกระทำ
    name: ชื่อ
    terminationStartDate: วันที่เริ่มต้น
  referralTree:
    deposit: เงินฝาก
    direct: ขายตรง
    level: ระดับ
    referrals: การอ้างอิง
    turnover: มูลค่าการซื้อขาย
  staking:
    marketCap: มูลค่าตลาด
  trades:
    attachment: หลักฐานภาพถ่าย
    detail: รายละเอียด
    entryDate: วันที่เข้า
    entryPrice: ราคาเข้า
    exitDate: วันที่ออก
    exitPrice: ราคาออก
    margin: ขอบ
    pair: คู่
    profit: กำไร
    type: พิมพ์
  transactions:
    amount: ประเภท/จำนวน
    date: วันเวลา
    id: รหัสธุรกรรม
    type: พิมพ์
teamCenter:
  referralTree:
    title: ภาพรวมโครงสร้าง
toasts:
  success:
    general: ความสำเร็จ!
traders:
  feedTitle: ประวัติการซื้อขาย
  title: เทรดเดอร์
