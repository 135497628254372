bonuses:
  FEE_COMPENSATION: Náhrada poplatku
  FUND_TRANSFER: Vklad
  FUND_TRANSFER_FEE: Poplatok za prevod prostriedkov
  MATCHING_BONUS: Matching bonus
  PARTNER_TURNOVER: Unilevel bonus
  PAYMENT: Platba
  PRODUCT_PURCHASE: Nákup produktu
  PRODUCT_PURCHASE_FEE: Nákup produktu
  PROFIT: Zisk
  PROFIT_LOCKED: Zisk
  REWARD: Odmena
  TOKEN_EXCHANGE: Výmena tokenov
  UNILEVEL_BONUS: Unilevel bonus
  WALLET_TRANSFER: Výmena tokenov
  WITHDRAW: Výber
  WITHDRAW_FEE: Poplatok za výber
button:
  about: Onás
  addressCopied: Odkaz bol skopírovaný
  back: Späť
  cancel: Vymazanie
  changeAvatar: Zmeňte si avatara
  close: Uzavretie
  configureTotp: Nastavenie 2FA
  confirm: Potvrď
  copied: Odkaz bol skopírovaný
  copyReflink: Skopírujte odkaz na sprostredkovanie
  createAccount: Vytvoriť účet
  deposit: Nákup
  download: Stiahnuť ▼
  go: Môže ísť
  home: Späť
  joinWaitlist: Začnime cestu!
  logIn: Prihlásiť sa
  logOut: Odhlásiť sa
  saveChanges: Uložiť zmenu
  submit: Odosielanie
  toggle_dark: Tmavý režim
  toggle_langs: Zmeniť jazyk
  updatePassword: Aktualizovať heslo
confirmation:
  subtitle: 'Akciu potvrďte zadaním kódu, ktorý sme vám práve poslali e-mailom.'
  title: Potvrďte akciu
dialog:
  confirmation:
    title: Potvrdenie
  deposit:
    insufficientFunds: Nedostatok prostriedkov
    title: Doplňte zostatok
  requestCard:
    preferredType: ''
    subtitle: ''
    title: ''
fastStart:
  REFERRALLINK:
    title: Nájdite svoj odkaz na odporúčanie
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: Začiatok
  TLCANNOUNCEMENTS:
    title: Nájdite aktualizácie TLC
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: Prvý vklad
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: Získať podporu
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: Získajte balík
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: Získajte marketingové pdf
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: Pozrite si svoj tím
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: Vyberte prostriedky
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: Výsledky obchodovania
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2024
forms:
  amount:
    label: Suma
  amountMax:
    label: Suma (max)
  avatar:
    edited: Avatar sa zmenil
    label: Avatar
  balance:
    label: Zostatok
  bedType:
    label: Zlý tip
  code:
    label: Kód
  country:
    label: Krajina
  deposit:
    label: Nákup
  description:
    label: Popis
  email:
    exists: Tento e-mail už existuje
    invalid: Táto e-mailová adresa je neplatná
    label: E-mail
    labelRegistration: Vaša emailová adresa
    notExists: Ten email neexistuje
    required: Vyžaduje sa e-mailová adresa
  emailOrNickname:
    label: Email alebo prezývka
  enabled:
    label: Povolený
  endDate:
    label: Dátum ukončenia
  endsAt:
    label: Končí to
  endsIn:
    label: Očakávané trvanie
  errors:
    alphanumeric: Povolené sú len písmená a čísla
    dateAfterNow: Dátum musí byť v budúcnosti
    number:
      positive: Hodnota musí byť väčšia ako 0
    required: Toto pole je povinné
    startDateAfterEndDate: Dátum začiatku musí byť skorší ako dátum ukončenia
  expiryDate:
    label: Dátum spotreby
  field:
    label: Lúka
  firstName:
    label: Krstné meno
    placeholder: John
  fullName:
    label: Celé meno
    placeholder: Jano Doe
  id:
    label: ID
  lastName:
    label: Priezvisko
    placeholder: Doe
  name:
    label: Meno
    placeholder: Meno
  nationalNumber:
    label: Identifikátor krajiny
  newPassword:
    label: Nové heslo
  newPasswordRepeat:
    label: Opäť nové heslo
  nickname:
    exists: Túto prezývku už niekto používa
    label: Prezývka
  oldPassword:
    label: Staré heslo
  password:
    error: Heslá sa nezhodujú
    label: 'Heslo  '
  passwordRepeat:
    label: Zopakujte heslo
  phoneNumber:
    errors:
      invalid: Neplatné telefónne číslo
    label: Telefónne číslo
  phonePrefix:
    label: Predvolba
  postalCode:
    label: Poštové smerovacie číslo
    placeholder: Poštové smerovacie číslo
  price:
    label: cena
  privacyPolicy:
    label: 'Súhlasím s {policy} a {tc}'
    policy:
      label: Zásady ochrany osobných údajov
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: Podmienky
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: Prebieha
  quantity:
    label: Množstvo
  query:
    label: Dopyt
    notExists: Zadaný používateľ neexistuje
  question:
    label: Otázka
    placeholder: Tu zadajte svoju otázku
  remarks:
    label: Komentujte
  response:
    label: Odpoveď
  secondName:
    label: Druhé krstné meno
  status:
    active:
      label: Aktívne
    label: Postavenie
    notActive:
      label: Neaktívne
  timezone:
    label: Časové pásmo
  title:
    label: Titul
  type:
    label: Typ
  value:
    label: Hodnota
global:
  daysShort: deň
  hoursShort: Hodiny
  minutesShort: mesiac
  people: Osoba
history:
  fields:
    date: Dátum
    description: Popis
    id: Identifikácia
    price: cena
  filter:
    byBonus: Filtrujte podľa bonusu
  type:
    PAYMENT: Platba
    PRODUCT_PURCHASED: Zakúpený produkt
    SALES_COMMISSION: Predajná provízia
    WITHDRAW: Odčítanie
home:
  commission:
    currentLevel: Aktuálna hodnosť
    deposit: Záloha
    getLink: Vytvorte reflink
    requestCard: ''
    title: Peňaženka
    transfer: Prevod
    withdraw: Výber
    yourSponsor: Sponzor
  sales:
    activeReferrals: Aktívni členovia tímu
    title: Štatistiky predaja
    total: Celková provízia USD
  statistics:
    of: '{0} z {1}'
    title: Zhrnutie
  transactions:
    title: História transakcií
  wallets:
    DEPOSIT:
      title: Vkladová peňaženka
    title: Zostatok
    transfer:
      subtitle: Vyberte si preferovanú peňaženku
      title: Preferovaná peňaženka na výber zostatku
    WITHDRAW:
      title: Výberová peňaženka
homeStats:
  direct: Priamy obrat
  directReferrals: Priami členovia tímu
  team: Člen tímu
  volume: Tímový obrat
marketing:
  presentation:
    cta: Stiahnite si prezentáciu
    subtitle: Jazyk prezentácie
    title: Stiahnite si prezentáciu TLC
  title: Marketingový materiál
menu:
  blog: Správy
  bonuses: Transakcie
  contest: Leader propagácia
  copyReflink: Kopírovať odkaz
  dashboard: Ovládací panel
  fastStart: Začiatok
  history: Historia provízií
  knowledgeBase: Úložisko vedomostí
  logOut: Odhlásiť sa
  marketing: Marketingový materiál
  products: Produkty
  settings: Nastavenia
  shareReflink: Zdieľať Reflink
  structure: Štruktúra tímu
  support: Podpora
  teamCenter: Tímové centrum
  traders: Obchodníci
  you: Vy
navigation:
  title:
    blog: Správy
    confirmation: Potvrdenie
    dashboard: Ovládací panel
    fastStart: Rýchly štart
    marketing: Marketingový materiál
    packages: Balíčky
    settings: nastavenie
    statistics: História transakcií
    structure: Štruktúra tímu
    support: Podpora
    teamCenter: Tímové centrum
    traders: Prehľad obchodu
not-found: Nenájdené
ownedProducts:
  title: Vlastné balíčky
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: Záloha
    days: dní
    infoAmount: 'To vám umožní kúpiť balík v hodnote ${amount}'
    payTo: >-
      Pošlite prostriedky na nasledujúcu adresu USD TRC20. <br> Platba zahŕňa
      poplatok 1 % + 2 $.
    redirect: Čoskoro budete presmerovaní
    warning: >-
      Po kliknutí na vklad odošlite presne rovnakú sumu, akú zobrazuje váš
      platobný systém.
  confirmation:
    text: Si si istý?
  summerPromo:
    button: Pokračovanie
    text: >-
      Vzhľadom na letné prázdniny pripravujeme s extra zľavami! Mesačné
      predplatné je teraz 119 USD a dvojmesačné 199 USD
    title: Letná akcia!
  transfer:
    address: Adresa
    addressLabel: Adresa Tron
    amount: Suma
    amountLabel: množstvoLabel
    button: Prevod
    status: Postavenie
    success: Úspešný prevod
    text: >-
      Ak chcete previesť finančné prostriedky na iného používateľa, vyplňte
      formulár.<br> Z vašej peňaženky bude stiahnutý poplatok vo výške 1 %.
    title: Prevod
  withdraw:
    address: Adresa
    addressLabel: Adresa Tron
    amount: Suma
    amountLabel: množstvoLabel
    button: Výber
    status: Postavenie
    success: Výber iniciovaný
    text: 'Zadajte sieťovú adresu tron, na ktorú chcete prijať prevod USDT.'
    title: 'Zadajte adresu siete tron, na ktorú chcete prijať prevod USDT.'
  withdrawSummary:
    title: Vybrať súhrn
products:
  inputPrice: 'Ak chcete pokračovať, vyberte balík'
  owned:
    limit: Disponibilný zostatok
    none: Nemáte vlastný balík
    title: Vlastné balíčky
  timeToEnd: Čas do vypršania platnosti
  title: Kúpte si balík
settings:
  authentication:
    title: Identifikácia
  personal:
    title: Osobné údaje
  profile:
    cta: Nové nahranie
    language: 'Jazyk  '
    subtitle: Ako to vidí iný používateľ
    title: Váš avatar
  sponsor:
    title: Tvoj sponzor
  telegram:
    title: Telegramové pripojenie
signup:
  alreadyRegistered: Už zaregistrovaný?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: Staňte sa súčasťou obchodnej revolúcie
    title: Úplne prvá obchodná AI
  privacy: Zásady ochrany osobných údajov
  regulations: Súhlasím s pravidlami a podmienkami TLC
  subtitle: >-
    {nickname} ma pozval do projektu. Ak chcete pokračovať, vyplňte formulár
    nižšie.
  terms: Podmienky používania
  title: Pridať sa teraz!
statistics:
  max: Max
  sum: Suma
  sumDay: Dnešná suma
  sumMonth: Mesačná suma
structure:
  activeUntil: 'Zatiaľ aktívne:'
  copyReflink: Kopírovať Reflink
  directReferrals: 'Priamy tím '
  inactive: Neaktívne
  invested: Uložené
  joined: Pripojené
  referrals: Odporúčanie
  search: Vyhľadajte osobu
  showDetails: Zobraziť podrobnosti
  title: Štruktúra sprostredkovania
  totalPackages: Celkový počet balíkov
  turnover: Obrat
support:
  alreadyRegistered: Už zaregistrovaný?
  footer:
    subtitle: >-
      Ak máte akékoľvek problémy s produktmi a chcete od nás získať pomoc,
      pošlite nám e-mail na adresu support{'@'}tradelikecrazy.ai.
    title: Všeobecná žiadosť o pomoc
  header:
    subtitle: >-
      Ak máte akékoľvek problémy s produktmi a chcete od nás získať pomoc,
      pošlite nám e-mail na adresu support{'@'}tradelikecrazy.ai.
    title: Technický problém
  privacy: Zásady ochrany osobných údajov
  subtitle: Na odoslanie lístka vyplňte formulár
  terms: Podmienky používania
  title: Podanie lístka
table:
  headers:
    action: Akcia
    name: názov
    terminationStartDate: Dátum začiatku
  referralTree:
    deposit: Záloha
    direct: Priamy predaj
    level: úroveň
    referrals: Odporúčania
    turnover: Obrat
  staking:
    marketCap: Trhová kapitalizácia
  trades:
    attachment: Foto dôkaz
    detail: Detail
    entryDate: Vložte dátum
    entryPrice: Cena vstupného
    exitDate: Dátum odchodu
    exitPrice: Výstupná cena
    margin: Zisk
    pair: Pár
    profit: Zisk
    type: Typ
  transactions:
    amount: Typ/množstvo
    date: Dátum Čas
    id: ID transakcie
    type: Typ
teamCenter:
  referralTree:
    title: Prehľad štruktúry
toasts:
  success:
    general: Úspešná transakcia!
traders:
  feedTitle: Príbeh obchodovania
  title: Obchodníci
