<script lang="ts" setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import deIcon from '~/assets/icons/locales/de.svg?component'
import enIcon from '~/assets/icons/locales/en.svg?component'
import esIcon from '~/assets/icons/locales/es.svg?component'
import huIcon from '~/assets/icons/locales/hu.svg?component'
import krIcon from '~/assets/icons/locales/kr.svg?component'
import ptIcon from '~/assets/icons/locales/pt.svg?component'
import ruIcon from '~/assets/icons/locales/ru.svg?component'
import vnIcon from '~/assets/icons/locales/vn.svg?component'
import zhIcon from '~/assets/icons/locales/zh-cn.svg?component'
import czIcon from '~/assets/icons/locales/cz.svg?component'
import skIcon from '~/assets/icons/locales/sk.svg?component'
import thIcon from '~/assets/icons/locales/th.svg?component'
import SelectArrow from '~/assets/icons/select-arrow.svg?component'

const { locale } = useI18n()

const localeList = [
  {
    title: 'EN',
    value: 'en',
    icon: enIcon,
  },
  {
    title: 'ES',
    value: 'es',
    icon: esIcon,
  },
  {
    title: 'HU',
    value: 'hu',
    icon: huIcon,
  },
  {
    title: 'KR',
    value: 'kr',
    icon: krIcon,
  },
  {
    title: 'PT',
    value: 'pt',
    icon: ptIcon,
  },
  {
    title: 'RU',
    value: 'ru',
    icon: ruIcon,
  },
  {
    title: 'VN',
    value: 'vn',
    icon: vnIcon,
  },
  {
    title: 'CN',
    value: 'zh-cn',
    icon: zhIcon,
  },
  {
    title: 'DE',
    value: 'de',
    icon: deIcon,
  },
  {
    title: 'CZ',
    value: 'cz',
    icon: czIcon,
  },
  {
    title: 'SK',
    value: 'sk',
    icon: skIcon,
  },
  {
    title: 'TH',
    value: 'th',
    icon: thIcon,
  },
] as const

const currentLocale = computed(() => {
  return localeList.find((item) => item.value === locale.value)
})

const changeLocale = (value: string) => {
  locale.value = value
}
</script>

<template>
  <Menu
    as="div"
    class="relative text-black inline-block text-left min-w-[104px] focus:outline-none"
  >
    <div>
      <MenuButton
        class="inline-flex uppercase min-w-[50px] text-center gap-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-black focus:outline-none"
      >
        <component class="w-[22px] h-[18px]   block" :is="currentLocale?.icon" />
        <span>{{ currentLocale?.title }}</span>
        <component :is="SelectArrow" class="w-2 h-2" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 z-[10000] mt-2 backdrop-blur-[10px] w-full origin-top-right rounded-md bg-[rgba(222,233,253,0.80)] focus:outline-none"
      >
        <div v-for="value of localeList" :key="value.value" class="p-1">
          <MenuItem>
            <button
              :class="[
                'group grid w-full grid-cols-2 p-1 items-stretch rounded-md text-sm gap-10 text-ultimate-gray hover:bg-[#CAD9F1] hover:shadow-sm hover:text-black',
              ]"
              @click="changeLocale(value.value)"
            >
              <span>{{ value.title }}</span>
              <component class="w-[22px] h-[18px]" :is="value.icon" />
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<style lang="scss" scoped></style>
