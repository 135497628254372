disabled
<script setup lang="ts">
const props = defineProps({
  prefix: {
    type: String,
    default: 'icon',
  },
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: '#F3701F',
  },
  hoverColor: {
    type: String,
    default: '',
  },
  width: {
    type: [Number, String],
    default: 16,
  },
  height: {
    type: [Number, String],
    default: 16,
  },
})

const symbolId = computed(() => `#${props.prefix}-${props.name}`)

const getFirstChildNode = (el: HTMLElement): HTMLElement => {
  const childNodes = el.childNodes
  return childNodes[0] as HTMLElement
}

const onMouseEnter = (e: MouseEvent) => {
  if (!props.hoverColor) return

  const childNode = getFirstChildNode(e.target as HTMLElement)
  if (!childNode) return

  childNode.style.fill = props.hoverColor
}

const onMouseOut = (e: MouseEvent) => {
  if (!props.hoverColor) return

  const childNode = getFirstChildNode(e.target as HTMLElement)
  if (!childNode) return

  childNode.style.fill = props.color
}
</script>

<template>
  <svg
    class="app-svg-icon"
    :width="props.width"
    :height="props.height"
    aria-hidden="true"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseOut"
  >
    <use class="transition-all duration-200" :href="symbolId" :fill="props.color" />
  </svg>
</template>
