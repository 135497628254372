<script setup lang="ts">
import AppDialog from '~/components/dialog/AppDialog.vue'
import AppButton from '~/components/utility/AppButton.vue'

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  title: {
    type: String,
    default: 'Confirmation',
  },
  content: {
    type: String,
    default: 'Are you sure that You want to proceed?',
  },
})

const emit = defineEmits(['update:modelValue', 'confirm', 'reject'])

const handleClose = () => {
  emit('update:modelValue', false)
}

const handleConfirm = () => {
  handleClose()
  emit('confirm')
}

const handleReject = () => {
  handleClose()
  emit('reject')
}

const handleDialogModel = (isOpen: Boolean) => {
  if (!isOpen) {
    handleClose()
    emit('reject')
    return
  }
  emit('update:modelValue', isOpen)
}
</script>

<template>
  <AppDialog
    :model-value="props.modelValue"
    :title="props.title"
    @update:model-value="handleDialogModel"
    class="z-1000 text-black"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
    <slot>
      <p class="mt-6">{{ props.content }}</p>
    </slot>
    <template #actions>
      <slot name="actions" :close="handleReject" :reject="handleReject" :confirm="handleConfirm">
        <AppButton
          type="button"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
          @click="handleConfirm"
        >
          Confirm
        </AppButton>
        <AppButton
          secondary
          type="button"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          @click="handleReject"
        >
          Cancel
        </AppButton>
      </slot>
    </template>
  </AppDialog>
</template>
