import { createI18n } from 'vue-i18n'
import Cookies from 'js-cookie'
import type { UserModule } from '~/types'

// Import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
//
// Don't need this? Try vitesse-lite: https://github.com/antfu/vitesse-lite
const messages = Object.fromEntries(
  Object.entries(import.meta.globEager('../../locales/*.y(a)?ml')).map(([key, value]) => {
    const yaml = key.endsWith('.yaml')
    return [key.slice(14, yaml ? -5 : -4), value.default]
  }),
)

const availableLocales = Object.keys(messages)

export const install: UserModule = ({ app, router }) => {
  // @ts-expect-error browser compat
  const browserLocale = (navigator.language || navigator.userLanguage).split('-')[0] || undefined
  const defaultLocale = 'en'
  const isBrowserLocaleSupported = availableLocales.includes(browserLocale)

  const i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    messages,
  })

  app.use(i18n)

  router.beforeEach((to) => {
    const { fullPath } = to
    const splittedFullPath = fullPath.split('/')
    const toLocale = splittedFullPath[1]
    const isValidLocale = availableLocales.includes(toLocale)

    const savedLocale = import.meta.env.SSR ? '' : Cookies.get('locale')
    const saveLocale = (locale: string) => {
      if (i18n.global.locale.value === locale && savedLocale === locale) {
        return
      }

      if (!import.meta.env.SSR) {
        Cookies.set('locale', locale)
        document.documentElement.lang = locale
      }
      i18n.global.locale.value = locale
    }

    const getLocale = () => {
      return savedLocale || (isBrowserLocaleSupported ? browserLocale : defaultLocale)
    }

    const getToPathWithLocale = (path: string) => {
      return `/${getLocale()}${path}`
    }

    if (!toLocale || !isValidLocale) {
      const resolvedRedirectPath = router.resolve(getToPathWithLocale(to.fullPath))
      if (resolvedRedirectPath.matched.length) {
        return getToPathWithLocale(to.fullPath)
      }

      return getToPathWithLocale('/404/')
    }

    saveLocale(toLocale)
    if (!to.matched.length) {
      return `/${toLocale}/404/`
    }
  })
}
