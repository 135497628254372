bonuses:
  FEE_COMPENSATION: Díj kompenzáció
  FUND_TRANSFER: Befizetés
  FUND_TRANSFER_FEE: Pénzátutalási díj
  MATCHING_BONUS: Matching bonus
  PARTNER_TURNOVER: Unilevel bonus
  PAYMENT: Kifizetés
  PRODUCT_PURCHASE: Termék vásárlása
  PRODUCT_PURCHASE_FEE: Termékvásárlás
  PROFIT: Profit
  PROFIT_LOCKED: Profit
  REWARD: Jutalom
  TOKEN_EXCHANGE: Token átváltás
  UNILEVEL_BONUS: Unilevel bónusz
  WALLET_TRANSFER: Token átváltás
  WITHDRAW: Kiutalás
  WITHDRAW_FEE: Kiutalási díj
button:
  about: Ról ről
  addressCopied: Link kimásolva
  back: Vissza
  cancel: Törlés
  changeAvatar: Avatar módosítása
  close: Bezárás
  configureTotp: 2FA beállítása
  confirm: Megerősít
  copied: Link kimásolva
  copyReflink: Ajánlólink másolása
  createAccount: Számla létrehozása
  deposit: Vásárlás
  download: Letöltés
  go: Mehet
  home: Vissza
  joinWaitlist: Kezdjük az utazást!
  logIn: Bejelentkezés
  logOut: Kijelentkezés
  saveChanges: Változtatás mentése
  submit: Küldés
  toggle_dark: Sötét mód
  toggle_langs: Nyelv változtatása
  updatePassword: Jelszó változatása
confirmation:
  subtitle: >-
    Írja be a kódot, amelyet az imént e-mailben küldtünk Önnek a művelet
    megerősítéséhez.
  title: A művelet megerősítése
dialog:
  confirmation:
    title: Megerősít
  deposit:
    insufficientFunds: 'Elégtelen egyenleg. Kérem, hogy töltse fel egyenlegét!'
    title: Egyenleg feltöltése
fastStart:
  REFERRALLINK:
    title: Keresse meg hivatkozási linkjét
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: Kezdés
  TLCANNOUNCEMENTS:
    title: Tlc frissítések keresése
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: Első befizetés
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: Támogatást kapni
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: Szerezzen csomagot
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: Szerezd meg a marketing pdf-et
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: Lásd a csapatot
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: Vedd ki az alapokat
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: Kereskedési eredmények
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2024
forms:
  amount:
    label: Összeg
  amountMax:
    label: Összeg
  avatar:
    edited: Avatar módosult
    label: Avatar
  balance:
    label: Egyenleg
  bedType:
    label: '????? Ágytípus'
  code:
    label: Kód
  country:
    label: Ország
  deposit:
    label: Vásárlás
  description:
    label: Leírás
  email:
    exists: Ezt az e-mail már létezik
    invalid: Ez az e-mail cím érvénytelen
    label: E-mail
    labelRegistration: Az Ön e-mail címe
    notExists: Az az e-mail nem létezik
    required: E-mail cím szükséges
  emailOrNickname:
    label: E-mail vagy becenéc
  enabled:
    label: Engedélyezve
  endDate:
    label: Végdátum
  endsAt:
    label: Végződik
  endsIn:
    label: Várható időtartam
  errors:
    alphanumeric: Csak betűk és számok megengedettek
    dateAfterNow: A dátumnak jövőbelinek kell lennie
    number:
      positive: Az értéknek 0-nál nagyobbnak kell lennie
    required: Ez a mező kötelező
    startDateAfterEndDate: A kezdő dátumnak a végdátum előtt kell lennie
  expiryDate:
    label: Lejárati dátum
  field:
    label: Mező
  firstName:
    label: Keresztnév
    placeholder: János
  fullName:
    label: Teljes Név
    placeholder: Szabó János
  id:
    label: ID
  lastName:
    label: Családi név
    placeholder: 'Szabó   '
  name:
    label: Név
    placeholder: Név
  nationalNumber:
    label: Ország azonosító
  newPassword:
    label: Új jelszó
  newPasswordRepeat:
    label: Új jelszó még egyszer
  nickname:
    exists: Ezt a becenevet már használja valaki
    label: Becenév
  oldPassword:
    label: Régi jelszó
  password:
    error: A jelszavak nem egyeznek
    label: 'Jelszó  '
  passwordRepeat:
    label: Jelszó ismétlése
  phoneNumber:
    errors:
      invalid: Érvénytelen telefonszám
    label: Telefonszám
  phonePrefix:
    label: Előhívó
  postalCode:
    label: Irányítószám
    placeholder: Irányítószám
  price:
    label: Ár
  privacyPolicy:
    label: 'Egyetértek a következővel: {policy} és {tc}'
    policy:
      label: Adatvédelmi irányelvek
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: Felhasználási feltételek
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: Folyamatban
  quantity:
    label: Mennyiség
  query:
    label: Lekérdezés
    notExists: A megadott felhasználó nem létezik
  question:
    label: Kérdés
    placeholder: Ide írja be kérdését
  remarks:
    label: Megjegyzés
  response:
    label: Válasz
  secondName:
    label: Második keresztnév
  status:
    active:
      label: Aktív
    label: Státusz
    notActive:
      label: Nem aktív
  timezone:
    label: Időzóna
  title:
    label: Cím
  type:
    label: Típus
  value:
    label: Érték
global:
  daysShort: Nap
  hoursShort: Óra
  minutesShort: Hónap
  people: Személy
history:
  fields:
    date: Dátum
    description: Leírás
    id: Azonosító
    price: Ár
  filter:
    byBonus: Szűrés bónusz szerint
  type:
    PAYMENT: Kifizetés
    PRODUCT_PURCHASED: Megvásárolt termék
    SALES_COMMISSION: Értékesítési jutalék
    WITHDRAW: Kivonás
home:
  commission:
    currentLevel: Jelenlegi helyezés
    deposit: Letét
    getLink: Reflink létrehozása
    requestCard: ''
    title: Tárca
    transfer: Transzfer
    withdraw: Kivonás
    yourSponsor: Szponzor
  sales:
    activeReferrals: Aktív csapattagok
    title: Eladási statisztika
    total: Total jutalék USD
  statistics:
    of: '{0} / {1}'
    title: Összegzés
  transactions:
    title: Tranzakciótörténet
  wallets:
    DEPOSIT:
      title: Letéti tárca
    title: Egyenleg
    transfer:
      subtitle: Preferált tárca kiválasztása
      title: Preferált tárca egyenleg kivonásához
    WITHDRAW:
      title: Kivonási tárca
homeStats:
  direct: Direkt forgalom
  directReferrals: Direkt csapattagok
  team: Csapattag
  volume: Csapatforgalom
marketing:
  presentation:
    cta: Prezentáció letöltése
    subtitle: Prezentáció nyelve
    title: TLC prezentáció letöltése
  title: Marketing anyag
menu:
  blog: Hírek
  bonuses: Tranzakciók
  contest: Vezetői promóció
  copyReflink: Reflink másolása
  dashboard: Irányítópult
  fastStart: Kezdés
  history: Jutalék történet
  knowledgeBase: Tudástár
  logOut: Kijelentkezés
  marketing: Marketing anyag
  products: Termékek
  settings: Beállítások
  shareReflink: Reflik megosztása
  structure: Csapatstruktúra
  support: Támogatás
  teamCenter: Csapat központ
  traders: Kereskedők
  you: Te
navigation:
  title:
    blog: Hírek
    confirmation: Megerősítés
    dashboard: Irányítópult
    fastStart: Gyors kezdés
    marketing: Marketing anyag
    packages: Csomagok
    settings: Beállítások
    statistics: Tranzakciós történet
    structure: Csapatstruktúra
    support: Támogatás
    teamCenter: Csapat központ
    traders: Kereskedési áttekintés
not-found: Nem találtam
ownedProducts:
  title: Saját csomagok
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Ezüst
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: Letét
    days: napok
    infoAmount: >-
      Ez lehetővé teszi, hogy megvásároljon egy csomagot, amelynek értéke
      ${amount}
    payTo: >-
      Kérjük, küldje el az összeget a következő USD TRC20 címre. <br> A fizetés
      2 USD díjat tartalmaz.
    redirect: Hamarosan átirányítjuk
    warning: >-
      Kérjük, pontosan ugyanazt az összeget küldje el, mint amennyit a fizetési
      rendszer mutatja, miután rákattintott a befizetésre.
  confirmation:
    text: Biztos vagy benne?
  summerPromo:
    button: Folytatás
    text: >-
      A nyári szünet miatt extra kedvezményekkel készülünk! A havi előfizetés
      most 119 dollár, a kéthavi előfizetés pedig 199 dollár
    title: Nyári promóció!
  transfer:
    address: Cím
    addressLabel: Tron cím
    amount: Összeg
    amountLabel: mennyiségLabel
    button: Transzfer
    status: Státusz
    success: Sikeres transzfer
    text: >-
      Ha pénzt szeretne átutalni egy másik felhasználónak, töltse ki az
      űrlapot.<br> Az 1%-os díj a pénztárcájából kerül felszámításra.
    title: Utalás
  withdraw:
    address: Cím
    addressLabel: Tron cím
    amount: Összeg
    amountLabel: mennyiségLabel
    button: Kivonás
    status: Státusz
    success: Kivonás kezdeményezve
    text: >-
      Kérjük, adja meg a tron hálózati címét, amelyre USDT-átutalást szeretne
      kapni.
    title: >-
      Kérjük, adja meg a tron hálózati címét, amelyre USDT-átutalást szeretne
      kapni.
  withdrawSummary:
    title: Összefoglaló visszavonása
products:
  inputPrice: Válassza ki a csomagot a továbblépéshez
  owned:
    limit: Elérhető egyenleg
    none: Nincs saját csomagja
    title: Saját csomagok
  timeToEnd: Idő a lejáratig
  title: Csomag vásárlása
settings:
  authentication:
    title: Azonosítás
  personal:
    title: Személyes információk
  profile:
    cta: Új feltöltés
    language: 'Nyelv  '
    subtitle: Hogyan lát egy másik felhasználó
    title: Avatarod
  sponsor:
    title: A szponzorod
  telegram:
    title: Telegram kapcsolat
signup:
  alreadyRegistered: Már regisztrált?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: Légy a kereskedési forradalom részese
    title: A legelső kereskedési AI
  privacy: Adatvédelmi irányelvek
  regulations: Egyetértek a szabályzattal és feltételekkel
  subtitle: >-
    {nickname} meghívott a projektbe. A folytatáshoz töltse ki az alábbi
    űrlapot.
  terms: Felhasználási feltételek
  title: Csatlakozz most!
statistics:
  max: Max
  sum: Összeg
  sumDay: Mai összeg
  sumMonth: Havi összeg
structure:
  activeUntil: 'Aktív eddig:'
  copyReflink: Reflink másolása
  directReferrals: 'Direkt csapat '
  inactive: Inaktív
  invested: Letétbe helyezett
  joined: Csatlakozott
  referrals: Ajánlás
  search: Személy keresése
  showDetails: Részletek mutatása
  title: Ajánlási struktrúra
  totalPackages: Total csomagok
  turnover: Forgalom
support:
  alreadyRegistered: Már regisztrált?
  footer:
    subtitle: >-
      Ha bármilyen problémája van a termékekkel kapcsolatban, és segítséget
      szeretne kapni tőlünk, kérjük, írjon e-mailt a
      support{'@'}tradelikecrazy.ai címre.
    title: Általános segítségkérés
  header:
    subtitle: >-
      Ha bármilyen problémája van a termékekkel kapcsolatban, és segítséget
      szeretne kapni tőlünk, kérjük, írjon e-mailt a
      support{'@'}tradelikecrazy.ai címre.
    title: Technikai probléma
  privacy: Adatvédelmi irányelvek
  subtitle: Töltse ki a formanyomtatványt a ticket feladásához
  terms: Felhasználási feltételek
  title: Ticket feladása
table:
  headers:
    action: Akció
    name: Név
    terminationStartDate: Stard dátum
  referralTree:
    deposit: Letét
    direct: Direct eladás
    level: Szint
    referrals: Ajánlások
    turnover: Forgalom
  staking:
    marketCap: Piaci sapka
  trades:
    attachment: Fotó
    detail: Részlet
    entryDate: Belépési dátum
    entryPrice: Belépő ár
    exitDate: Kilépési dátum
    exitPrice: Kilépő ár
    margin: Nyereség
    pair: Pár
    profit: Profit
    type: Típus
  transactions:
    amount: Típus/mennyiség
    date: Dátum/idő
    id: Tranzakció azonosító
    type: Típus
teamCenter:
  referralTree:
    title: Struktúra áttekintése
toasts:
  success:
    general: Sikeres tranzakció!
traders:
  feedTitle: Kereskedési történet
  title: 'Kereskedők '
