import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApiFetch } from '~/composables'
import type { Currency } from '~/types/currencies'

export const useCurrenciesStore = defineStore('currencies', () => {
  const currencies = ref<Currency[]>([])

  function getCurrencies() {
    return useApiFetch(`/currencies`).get().json()
  }

  async function loadCurrencies() {
    const { data, error } = await getCurrencies()
    if (error.value) throw error.value

    currencies.value = data.value
    return data.value as Currency[]
  }

  function findByName(name: string) {
    return currencies.value.find((x) => x.acronym === name)
  }

  const usdt = computed(() => findByName('USDT'))
  const elar = computed(() => findByName('ELAR'))

  const burningableCurrencies = computed(() =>
    currencies.value.filter((x) => x?.tag?.includes('BURNINGABLE')),
  )

  const highlightedCurrencies = computed(() =>
    currencies.value.filter((x) => x?.tag?.includes('HIGHLIGHT')),
  )

  const stakingCurrencies = computed(() =>
    currencies.value.filter((x) => x?.tag?.includes('STAKEABLE')),
  )

  const sellableCurrencies = computed(() =>
    currencies.value.filter((x) => x?.tag?.includes('SELLABLE')),
  )

  const unsellableCurrencies = computed(() =>
    currencies.value.filter((x) => !x?.tag?.includes('SELLABLE')),
  )

  return {
    currencies,
    loadCurrencies,
    findByName,
    usdt,
    elar,
    burningableCurrencies,
    highlightedCurrencies,
    stakingCurrencies,
    sellableCurrencies,
    unsellableCurrencies,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCurrenciesStore as any, import.meta.hot))
