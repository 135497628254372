bonuses:
  FEE_COMPENSATION: Bồi thường phí
  FUND_TRANSFER: Chuyển quĩ
  FUND_TRANSFER_FEE: Phí chuyển tiền
  MATCHING_BONUS: Tiền thưởng phù hợp
  PARTNER_TURNOVER: Tiền thưởng đơn cấp
  PAYMENT: Sự chi trả
  PRODUCT_PURCHASE: Mua sản phẩm
  PRODUCT_PURCHASE_FEE: Mua sản phẩm
  PROFIT: Lợi nhuận
  PROFIT_LOCKED: Lợi nhuận
  REWARD: Phần thưởng
  TOKEN_EXCHANGE: Trao đổi mã thông báo
  UNILEVEL_BONUS: Tiền thưởng đơn cấp
  WALLET_TRANSFER: Trao đổi mã thông báo
  WITHDRAW: Rút
  WITHDRAW_FEE: Phí rút tiền
button:
  about: Về
  addressCopied: Liên kết đã được sao chép
  back: Mặt sau
  cancel: Hủy bỏ
  changeAvatar: Thay đổi hình đại diện
  close: Đóng
  configureTotp: Đặt 2FA
  confirm: Xác nhận
  copied: Liên kết đã được sao chép
  copyReflink: Sao chép liên kết lại
  createAccount: Tạo tài khoản
  deposit: Mua
  download: Tải xuống
  go: ĐI
  home: Trang chủ
  joinWaitlist: Bắt đầu cuộc hành trình!
  logIn: Đăng nhập
  logOut: Đăng xuất
  saveChanges: Lưu thay đổi
  submit: Nộp
  toggle_dark: Chuyển đổi chế độ tối
  toggle_langs: Thay đổi ngôn ngữ
  updatePassword: Cập nhật mật khẩu
confirmation:
  subtitle: Nhập mã mà chúng tôi vừa gửi email cho bạn để xác nhận hành động.
  title: Xác nhận hành động
dialog:
  confirmation:
    title: Xác nhận
  deposit:
    insufficientFunds: Không đủ tiền. Vui lòng gửi tiền trước.
    title: Tiền gửi
  requestCard:
    preferredType: ''
    subtitle: ''
    title: ''
fastStart:
  REFERRALLINK:
    title: Tìm liên kết giới thiệu của bạn
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: Bắt đầu
  TLCANNOUNCEMENTS:
    title: Tìm thông tin cập nhật tlc
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: Khoản tiền gửi đầu tiên
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: Nhận hỗ trợ
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: Nhận gói
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: Nhận tiếp thị pdf
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: Xem đội của bạn
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: Rút tiền
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: Kết quả giao dịch
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
forms:
  amount:
    label: Số lượng
  amountMax:
    label: 'Số tiền(tối đa ${max})'
  avatar:
    edited: Hình đại diện đã được thay đổi
    label: Hình đại diện
  balance:
    label: Sự cân bằng
  bedType:
    label: Loại giường
  code:
    label: Mã số
  country:
    label: Quốc gia
  deposit:
    label: Mua
  description:
    label: Sự miêu tả
  email:
    exists: Email này đã được sử dụng.
    invalid: Email này không hợp lệ.
    label: E-mail
    labelRegistration: Địa chỉ email của bạn
    notExists: Email này không tồn tại.
    required: Địa chỉ e-mail là bắt buộc
  emailOrNickname:
    label: Email hoặc biệt danh
  enabled:
    label: Đã bật
  endDate:
    label: Ngày cuối
  endsAt:
    label: Kết thúc tại
  endsIn:
    label: Thời gian ước tính
  errors:
    alphanumeric: Chỉ cho phép chữ cái và số
    dateAfterNow: Ngày phải ở trong tương lai
    number:
      positive: Giá trị phải lớn hơn 0
    required: Trường này là bắt buộc
    startDateAfterEndDate: Ngày bắt đầu phải trước ngày kết thúc
  expiryDate:
    label: Ngày hết hạn
  field:
    label: Cánh đồng
  firstName:
    label: Tên đầu tiên
    placeholder: John
  fullName:
    label: Họ và tên
    placeholder: John Doe
  id:
    label: NHẬN DẠNG
  lastName:
    label: Họ
    placeholder: Nai
  name:
    label: Tên
    placeholder: Tên
  nationalNumber:
    label: Số quốc gia
  newPassword:
    label: Mật khẩu mới
  newPasswordRepeat:
    label: Lặp lại mật khẩu mới
  nickname:
    exists: Biệt danh này đã được sử dụng
    label: Tên nick
  oldPassword:
    label: Mật khẩu cũ
  password:
    error: Mật khẩu không khớp
    label: Mật khẩu
  passwordRepeat:
    label: Lặp lại mật khẩu
  phoneNumber:
    errors:
      invalid: Số điện thoại không hợp lệ
    label: Số điện thoại
  phonePrefix:
    label: Tiếp đầu ngữ
  postalCode:
    label: Mã bưu điện
    placeholder: Mã bưu điện
  price:
    label: Giá
  privacyPolicy:
    label: 'Tôi đồng ý với {policy} và {tc}'
    policy:
      label: Chính sách bảo mật
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: Các điều khoản và điều kiện
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: Tiến triển
  quantity:
    label: Số lượng
  query:
    label: Truy vấn
    notExists: Người dùng đã cho không tồn tại.
  question:
    label: Câu hỏi
    placeholder: Nhập câu hỏi của bạn vào đây
  remarks:
    label: Bình luận
  response:
    label: Phản ứng
  secondName:
    label: Tên thứ hai
  status:
    active:
      label: Tích cực
    label: Trạng thái
    notActive:
      label: Không hoạt động
  timezone:
    label: Múi giờ
  title:
    label: Tiêu đề
  type:
    label: Kiểu
  value:
    label: Giá trị
global:
  daysShort: ĐĐ
  hoursShort: HH
  minutesShort: MM
  people: Người | Mọi người
history:
  fields:
    date: Ngày
    description: Sự miêu tả
    id: NHẬN DẠNG
    price: Giá
  filter:
    byBonus: Lọc theo tiền thưởng
  type:
    PAYMENT: Sự chi trả
    PRODUCT_PURCHASED: SẢN PHẨM ĐÃ MUA
    SALES_COMMISSION: HOA HỒNG BÁN HÀNG
    WITHDRAW: Rút
home:
  commission:
    currentLevel: Thứ hạng hiện tại của bạn
    deposit: Tiền gửi
    getLink: Tạo liên kết lại
    requestCard: ''
    title: Ví của bạn
    transfer: Chuyển khoản
    withdraw: Rút
    yourSponsor: 'Nhà tài trợ của bạn là: {sponsor}'
  sales:
    activeReferrals: 'Giới thiệu đang hoạt động: {0}'
    title: Thống kê bán hàng
    total: 'Tổng hoa hồng: {0} USD'
  statistics:
    of: '{0} trong số {1}'
    title: Bản tóm tắt
  transactions:
    title: Lịch sử giao dịch
  wallets:
    DEPOSIT:
      title: Ví tiền gửi
    title: Cân bằng
    transfer:
      subtitle: Chọn ví ưa thích
      title: Ví ưa thích để lấy tiền từ
    WITHDRAW:
      title: Rút ví
homeStats:
  direct: Doanh thu trực tiếp
  directReferrals: Giới thiệu trực tiếp
  team: Thành viên của đội
  volume: Doanh thu đội
marketing:
  presentation:
    cta: Nhận bản trình bày
    subtitle: Ngôn ngữ trình bày
    title: Tải xuống bản trình bày TLC
  title: Tiếp thị
menu:
  blog: Thông báo
  bonuses: Giao dịch
  contest: Thăng tiến lãnh đạo
  copyReflink: Sao chép liên kết lại
  dashboard: Bảng điều khiển
  fastStart: Bắt đầu
  history: Lịch sử hoa hồng
  knowledgeBase: Kiến thức cơ bản
  logOut: Đăng xuất
  marketing: Tiếp thị
  products: Các sản phẩm
  settings: Cài đặt
  shareReflink: Chia sẻ liên kết lại
  structure: Cấu trúc của bạn
  support: Ủng hộ
  teamCenter: Trung tâm đội
  traders: Thương nhân
  you: Bạn
navigation:
  title:
    blog: Thông báo
    confirmation: Xác nhận
    dashboard: Trang tổng quan của bạn
    fastStart: Bắt đầu nhanh
    marketing: Tiếp thị
    packages: gói
    settings: Cài đặt
    statistics: Lịch sử giao dịch
    structure: Cấu trúc của bạn
    support: Ủng hộ
    teamCenter: Trung tâm đội
    traders: Tổng quan giao dịch
not-found: Không tìm thấy
ownedProducts:
  title: Gói sở hữu
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: Tiền gửi
    days: ngày
    infoAmount: 'Nó sẽ cho phép bạn mua gói trị giá ${amount}'
    payTo: >-
      Vui lòng gửi tiền đến địa chỉ USD TRC20 sau đây. <br> Khoản thanh toán bao
      gồm phí 2$.
    redirect: Bạn sẽ được chuyển hướng sớm
    warning: >-
      Vui lòng gửi chính xác số tiền tương tự như hệ thống thanh toán hiển thị
      cho bạn sau khi bạn nhấp vào gửi tiền.
  confirmation:
    text: Bạn có chắc không?
  summerPromo:
    button: Tiếp tục
    text: >-
      Do kỳ nghỉ hè, chúng tôi đã chuẩn bị giảm giá thêm! Thuê bao hàng tháng
      bây giờ là 119$ và hai tháng là 199$
    title: Khuyến mãi mùa hè!
  transfer:
    address: Địa chỉ
    addressLabel: Địa chỉ Tron
    amount: Số lượng
    amountLabel: số lượngNhãn
    button: Chuyển khoản
    status: Trạng thái
    success: Chuyển khoản đã thành công.
    text: >-
      Để chuyển tiền cho người dùng khác, hãy điền vào biểu mẫu.<br> Phí 1% sẽ
      được tính từ ví của bạn.
    title: Chuyển tiền
  withdraw:
    address: Địa chỉ
    addressLabel: Địa chỉ Tron
    amount: Số lượng
    amountLabel: số lượngNhãn
    button: Rút
    status: Trạng thái
    success: Việc rút tiền đã được bắt đầu
    text: Vui lòng nhập địa chỉ mạng tron mà Bạn muốn nhận chuyển khoản USDT tới.
    title: Rút tiền
  withdrawSummary:
    title: Tóm tắt rút tiền
products:
  inputPrice: Chọn giá gói bên dưới để tiếp tục.
  owned:
    limit: 'Giới hạn có sẵn:'
    none: Bạn chưa sở hữu bất kỳ gói nào.
    title: Gói sở hữu
  timeToEnd: Thời gian để kết thúc
  title: Mua một gói
settings:
  authentication:
    title: Xác thực
  personal:
    title: Thông tin cá nhân
  profile:
    cta: Tải lên mới
    language: Ngôn ngữ
    subtitle: Cách người dùng khác nhìn thấy bạn
    title: Tóm tắt hồ sơ
  sponsor:
    title: Nhà tài trợ của bạn
  telegram:
    title: Kết nối điện tín
signup:
  alreadyRegistered: Đã đăng ký?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: Trở thành một phần của cuộc cách mạng giao dịch.
    title: AI giao dịch đầu tiên
  privacy: Chính sách bảo mật
  regulations: 'Tôi đồng ý với TradeLikeCrazy.ai {terms} và {privacy}'
  subtitle: >-
    Bạn đã được {nickname} mời tham gia dự án. Điền vào mẫu dưới đây để tiếp
    tục.
  terms: Điều khoản và điều kiện
  title: Tham gia cùng chúng tôi ngay!
statistics:
  max: Tối đa
  sum: Tổng
  sumDay: Tổng của ngày hôm nay
  sumMonth: Tổng số tháng
structure:
  activeUntil: Hoạt động cho đến khi
  copyReflink: Sao chép liên kết lại
  directReferrals: Chỉ đạo
  inactive: Không hoạt động
  invested: Đã gửi tiền
  joined: Đã tham gia
  referrals: Giới thiệu
  search: Tìm kiếm người
  showDetails: Hiển thị chi tiết
  title: Cây giới thiệu
  totalPackages: Tổng gói
  turnover: Doanh số
support:
  alreadyRegistered: Đã đăng ký?
  footer:
    subtitle: >-
      Nếu bạn có bất kỳ thách thức nào liên quan đến sản phẩm và muốn nhận trợ
      giúp từ chúng tôi, vui lòng gửi Email tới bộ phận hỗ
      trợ{'@'}Tradelikecrazy.ai
    title: Hỗ trợ chung
  header:
    subtitle: >-
      Nếu bạn có bất kỳ thách thức kỹ thuật nào và muốn nhận trợ giúp từ chúng
      tôi, vui lòng gửi email đến bộ phận hỗ trợ{'@'}Tradelikecrazy.ai
    title: Sự cố kỹ thuật
  privacy: Chính sách bảo mật
  subtitle: điền vào mẫu dưới đây để gửi một vé hỗ trợ.
  terms: Điều khoản và điều kiện
  title: Vé tham gia
table:
  headers:
    action: Hoạt động
    name: Tên
    terminationStartDate: Ngày bắt đầu
  referralTree:
    deposit: Tiền gửi
    direct: Bán trực tiếp
    level: Mức độ
    referrals: Giới thiệu
    turnover: Doanh số
  staking:
    marketCap: Vốn hóa thị trường
  trades:
    attachment: Bằng chứng ảnh
    detail: Chi tiết
    entryDate: Ngày nhập
    entryPrice: Giá vào lệnh
    exitDate: Ngày thoát
    exitPrice: Giá thoát
    margin: Lề
    pair: Đội
    profit: Lợi nhuận
    type: Kiểu
  transactions:
    amount: Loại/Số lượng
    date: Ngày giờ
    id: ID giao dịch
    type: Kiểu
teamCenter:
  referralTree:
    title: Tổng quan về cấu trúc
toasts:
  success:
    general: Thành công!
traders:
  feedTitle: Lịch sử giao dịch
  title: Thương nhân
