<script setup lang="ts">
import type { NavigationItem } from '~/types'
import Logo from '~/assets/logo.svg?component'

const props = withDefaults(
  defineProps<{
    navigationItems?: NavigationItem[]
  }>(),
  {
    navigationItems: () => [],
  },
)

const route = useRoute()

const isActive = (item: NavigationItem) => {
  return item.href && route.path.startsWith(item.href)
}

const isHovered = ref(false)
</script>

<template>
  <div
    class="fixed left-0 top-0 h-screen hidden lg:flex flex-col items-stretch inset-y-0 bg-primary-default/05 shadow backdrop-filter backdrop-blur-2xl z-1000 w-30"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <Logo class="fixed w-20 ml-6 mt-6" />
    <div class="flex-1 flex flex-col min-h-0 rounded-[0.625rem] overflow-hidden px-4">
      <nav class="flex flex-col items-stretch justify-center h-full py-4 space-y-1">
        <template v-for="item in props.navigationItems" :key="item.name">
          <router-link
            v-if="item.to"
            :to="item.to"
            class="relative group grid place-items-left pl-8 opacity-50 py-4 hover:(cursor-pointer opacity-100) transition transition-opacity"
            active-class="active-router-link !opacity-100"
            :aria-current="isActive(item) ? 'page' : undefined"
          >
            <div class="z-1000 flex flex-row items-center space-x-10">
              <component
                :is="item.icon"
                :name="item.icon"
                class="relative w-6 h-6 xl:(w-8 h-8) item-icon z-10 hover:cursor-pointer"
              />
              <span
                :class="[isHovered ? 'block' : 'hidden']"
                class="overflow-hidden w-40 text-primary-default font-bold"
                >{{ item.name }}</span
              >
            </div>
            <svg
              class="selection absolute left-[-20px] top-[2px] xl:top-0 transform -translate-y-full transition transition-opacity opacity-0 duration-500"
              xmlns="http://www.w3.org/2000/svg"
              width="53"
              height="40"
              viewBox="0 0 53 40"
              fill="none"
            >
              <path
                d="M0.0566406 0.5C5.69767 41.5257 52.3643 39.4744 52.3643 39.4744H0.0566406V0.5Z"
                fill="#BFD2FF"
              />
            </svg>
            <div
              class="selection absolute left-[-20px] top-1/2 w-[110%] h-[100%] xl:h-[110%] transform -translate-y-1/2 bg-[#BFD2FF] z-5 opacity-0 rounded-r-[2.5rem] transition transition-opacity duration-500"
            ></div>
            <svg
              class="selection absolute left-[-20px] bottom-[2px] xl:bottom-0 transform translate-y-full transition transition-opacity opacity-0 duration-500"
              xmlns="http://www.w3.org/2000/svg"
              width="53"
              height="39"
              viewBox="0 0 53 39"
              fill="none"
            >
              <path
                d="M0.0566406 38.5C5.66448 -1.48261 52.0566 0.516521 52.0566 0.516521H0.0566406V38.5Z"
                fill="#BFD2FF"
              />
            </svg>
          </router-link>
          <a
            v-if="item.href"
            :href="item.href"
            target="_blank"
            :class="['group menu-item']"
            :aria-current="isActive(item) ? 'page' : undefined"
          >
            <Component
              :is="item.icon"
              :class="['mr-4.5 flex-shrink-0 size-3 xl:size-5 menu-item__icon']"
            />
            {{ item.name }}
          </a>
        </template>
      </nav>
    </div>
  </div>
</template>

<style lang="css" scoped>
.active-router-link {
  .selection {
    @apply !opacity-100;
  }
}
</style>
