import { createFetch } from '@vueuse/core'
import { getToken } from '@baloise/vue-keycloak'

const apiFetch = createFetch({
  baseUrl: (import.meta.env.VITE_BASE_URL as string) || 'https://api.tradelikecrazy.ai/rest-api',
  options: {
    async beforeFetch({ options }) {
      const token = await getToken().catch((err) => {
        console.error(err)
        return undefined
      })

      if (token) {
        if (!options.headers) options.headers = {}

        // @ts-expect-error wrong types
        options.headers.Authorization = `Bearer ${token}`
      }

      return {
        options,
      }
    },
    async onFetchError(ctx) {
      console.error(ctx.response?.status)
      console.error(ctx?.data?.title)
      ctx.error = new Error(ctx?.data?.title)
      return ctx
    },
  },
  fetchOptions: {
    mode: 'cors',
  },
})

export const useApiFetch = apiFetch
