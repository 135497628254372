bonuses:
  FEE_COMPENSATION: 수수료 보상
  FUND_TRANSFER: 자금 이체
  FUND_TRANSFER_FEE: 자금 이체 수수료
  MATCHING_BONUS: 매칭보너스
  PARTNER_TURNOVER: 유니레벨 보너스
  PAYMENT: 결제
  PRODUCT_PURCHASE: 상품 구매하기
  PRODUCT_PURCHASE_FEE: 제품 구매
  PROFIT: 수익
  PROFIT_LOCKED: 수익
  REWARD: 보상
  TOKEN_EXCHANGE: 토큰 교환
  UNILEVEL_BONUS: 유니레벨 보너스
  WALLET_TRANSFER: 토큰 교환
  WITHDRAW: 출금
  WITHDRAW_FEE: 출금 수수료
button:
  about: About
  addressCopied: 링크가 복사되었습니다
  back: 뒤로
  cancel: 취소
  changeAvatar: 아바타 바꾸기
  close: 닫기
  configureTotp: 2FA 정하기
  confirm: 컨펌
  copied: 링크가 복사되었습니다
  copyReflink: 리퍼럴링크 복사
  createAccount: 계정 만들기
  deposit: 구매
  download: 다운로드
  go: GO
  home: 홈
  joinWaitlist: 여정을 시작하세요
  logIn: 로그인
  logOut: 로그아웃
  saveChanges: 바뀐내용 저장하기
  submit: 제출하기
  toggle_dark: 다크모드로 바꾸기
  toggle_langs: 언어 바꾸기
  updatePassword: 패스워드 업데이트
confirmation:
  subtitle: 작업을 확인하려면 방금 이메일로 보낸 코드를 입력하세요.
  title: 조치 확인
dialog:
  confirmation:
    title: 확정
  deposit:
    insufficientFunds: 자금이 부족합니다. 먼저 입금을 해주세요
    title: 입금하기
fastStart:
  REFERRALLINK:
    title: 추천 링크 찾기
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: 시작하기
  TLCANNOUNCEMENTS:
    title: TLC 업데이트 찾기
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: 첫 입금
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: 도움을 받다
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: 패키지 받기
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: 마케팅 PDF 받기
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: 팀 보기
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: 자금 인출
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: 거래 결과
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
forms:
  amount:
    label: 금액
  amountMax:
    label: '금액 (최대 ${max})'
  avatar:
    edited: 아바타가 바뀌었습니다
    label: 아바타
  balance:
    label: 잔고
  bedType:
    label: Bed type
  code:
    label: 암호
  country:
    label: 국가
  deposit:
    label: 구매
  description:
    label: 설명
  email:
    exists: 이미 사용되어진 이메일입니다
    invalid: 유효한 이메일이 아닙니다
    label: 이메일
    labelRegistration: 회원님의 이메일 주소
    notExists: 존재하지않는 이메일입니다
    required: 이메일 주소가 필요합니다
  emailOrNickname:
    label: 이메일 또는 닉네임
  enabled:
    label: 가능하게 함
  endDate:
    label: 끝나는 날짜
  endsAt:
    label: '~에 끝나는 '
  endsIn:
    label: 예상 기간
  errors:
    alphanumeric: 문자와 숫자만 허용됩니다.
    dateAfterNow: 날짜는 미래날짜여야만 합니다
    number:
      positive: 가치가 0보다 커야 함
    required: 이 필드가 필요합니다
    startDateAfterEndDate: 시작날짜는 끝나는 날짜보다 앞서야합니다
  expiryDate:
    label: 만료날짜
  field:
    label: 필드
  firstName:
    label: 이름 (성을 제외한)
    placeholder: John
  fullName:
    label: 이름 (성을 포함한)
    placeholder: John Doe
  id:
    label: ID
  lastName:
    label: 성
    placeholder: Doe
  name:
    label: 이름
    placeholder: 이름
  nationalNumber:
    label: 국가번호
  newPassword:
    label: 새로운 패스워드
  newPasswordRepeat:
    label: 새 패스워드를 다시 입력하세요
  nickname:
    exists: 이미 사용 중인 닉네임입니다
    label: 닉네임
  oldPassword:
    label: 예전 패스워드
  password:
    error: 패스워드가 틀립니다
    label: 패스워드
  passwordRepeat:
    label: 패스워드 다시 입력
  phoneNumber:
    errors:
      invalid: 전화번화가 유효하지않습니다
    label: 전화번호
  phonePrefix:
    label: Prefix
  postalCode:
    label: 우편번호
    placeholder: 우편번호
  price:
    label: 가격
  privacyPolicy:
    label: ' {policy} 및 {tc}에 동의합니다. '
    policy:
      label: 개인 정보 정책
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: 이용약관
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: 진행
  quantity:
    label: 수량
  query:
    label: 질문
    notExists: 사용자가 존재하지 않습니다
  question:
    label: 질문
    placeholder: 질문을 넣어주세요
  remarks:
    label: 비고
  response:
    label: 회답
  secondName:
    label: 두번째 이름
  status:
    active:
      label: 활성
    label: 상태
    notActive:
      label: 활성화안됨
  timezone:
    label: 타임존
  title:
    label: 제목
  type:
    label: 타입
  value:
    label: '가치 '
global:
  daysShort: DD
  hoursShort: HH
  minutesShort: MM
  people: 사람/회원
history:
  fields:
    date: '날짜 '
    description: 설명
    id: ID
    price: 가격
  filter:
    byBonus: 보너스에 의한 필터
  type:
    PAYMENT: 결제
    PRODUCT_PURCHASED: 팩키지제품구매
    SALES_COMMISSION: 매출수당
    WITHDRAW: 출금
home:
  commission:
    currentLevel: 회원님의 현재 직급
    deposit: '입금 '
    getLink: 리퍼럴링크 만들기
    requestCard: ''
    title: 회원님의 지갑
    transfer: 이체
    withdraw: 출금
    yourSponsor: '회원님의 스폰서: {sponsor}'
  sales:
    activeReferrals: '활성화된 리퍼럴: {0}'
    title: 매출통계
    total: '수당 총액: {0} USD'
  statistics:
    of: '{0} of {1}'
    title: 요약
  transactions:
    title: 거래 기록
  wallets:
    DEPOSIT:
      title: 입금 지갑
    title: 잔고
    transfer:
      subtitle: 원하는 지갑을 선택하세요
      title: ~ 부터
    WITHDRAW:
      title: 출금지갑
homeStats:
  direct: 직 매출
  directReferrals: 직접추천
  team: 팀멤버
  volume: 팀매출
marketing:
  presentation:
    cta: 프레젠테이션 갖기
    subtitle: 프레젠테이션 언어
    title: TLC 프레젠테이션 다운로도
  title: 마켓팅
menu:
  blog: 공지
  bonuses: '거래 '
  contest: 리더쉽 프로모우션
  copyReflink: 리퍼럴링크 복사
  dashboard: 대쉬보드
  fastStart: 시작하기
  history: 수당 내역
  knowledgeBase: 지식 기반
  logOut: 싸인아웃
  marketing: 마켓팅
  products: '상품 '
  settings: 셋팅
  shareReflink: 리퍼럴 링크 공유
  structure: 회원님 구조
  support: 지원
  teamCenter: 팀센터
  traders: 트레이더
  you: 회원님
navigation:
  title:
    blog: 공지
    confirmation: 확인
    dashboard: 대쉬보드
    fastStart: 빠른 시작
    marketing: 마켓팅
    packages: 팩키지
    settings: 셋팅
    statistics: 거래내역
    structure: 구조
    support: 지원
    teamCenter: 팀센터
    traders: 거래개요
not-found: 찾지못함
ownedProducts:
  title: 소유한 팩키지
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: 보증금
    days: 날
    infoAmount: '${amount}상당의 패키지를 구매하실 수 있습니다. '
    payTo: 다음 USD TRC20 주소로 자금을 보내주세요. <br> 결제에는 2$의 수수료가 포함됩니다.
    redirect: 곧 리디렉션됩니다.
    warning: 입금을 클릭하신 후 결제 시스템에 표시된 금액과 정확히 동일한 금액을 보내주세요.
  confirmation:
    text: 확실한가요?
  summerPromo:
    button: 계속
    text: '여름 휴가철을 맞이하여 추가 할인을 준비했습니다! 이제 월간 구독은 119$, 2개월 구독은 199$입니다.'
    title: 여름프로모우션
  transfer:
    address: 주소
    addressLabel: 트론 주소
    amount: 금액
    amountLabel: 금액라벨
    button: 이체
    status: 상태
    success: 이체가 성공되었습니다
    text: 다른 사용자에게 자금을 이체하려면 양식을 작성하세요.<br> 지갑에서 1%의 수수료가 청구됩니다.
    title: '자금이체 '
  withdraw:
    address: 주소
    addressLabel: 트론주소
    amount: 금액
    amountLabel: 금액라벨
    button: 출금
    status: 상태
    success: 출금이 시작되었습니다
    text: USDT를 전송받을 트론 네트워크 주소를 입력해 주세요.
    title: 자금 출금하기
  withdrawSummary:
    title: 출금요약
products:
  inputPrice: 아래있는 팩키지 가격을 선택하고 진행하세요
  owned:
    limit: 사용가능 한도
    none: 아무런 팩키지도 가지고 있지않습니다
    title: 소유한 팩키지
  timeToEnd: 끝날때까지 시간
  title: 팩키지 구매
settings:
  authentication:
    title: 인증
  personal:
    title: 개인정보
  profile:
    cta: '새로 업로도 '
    language: '언어  '
    subtitle: 다른 사용자가 회원님을 어떻게 보는지
    title: 회원님 아바타
  sponsor:
    title: 너의 스폰서
  telegram:
    title: 텔레그램 접속
signup:
  alreadyRegistered: 이미 등록되었습니다
  header:
    caption: 'TradeLikeCrazy.ai '
    subtitle: 트레이딩 혁명의 일원이 되세요
    title: '최초의 AI 트레이딩 '
  privacy: 개인정보 정책
  regulations: '나는 TradeLikeCrazy.ai {terms} 와 {privacy} 동의합니다'
  subtitle: '{nickname}이 회원님을 프로젝트에 초대하였습니다. 계속하려면 아래 양식을 작성하세요.'
  terms: 이용 약관
  title: 지금 가입하세요!
statistics:
  max: 최대
  sum: 합산
  sumDay: 오늘의 합산
  sumMonth: 월 합산
structure:
  activeUntil: ~ 까지 활성화됨
  copyReflink: 리퍼럴 링크 복사
  directReferrals: '직접 '
  inactive: 비활성화
  invested: 입금된
  joined: 가입된
  referrals: 리퍼럴
  search: 사람찾기
  showDetails: 상세하게 보여주기
  title: 추천트리
  totalPackages: 총 팩키지
  turnover: 매출
support:
  alreadyRegistered: 이미 등록되었나요?
  footer:
    subtitle: >-
      팩키지제품과 관련하여 어떤 유형의 문제가 발생하여 도움을 받고 싶으시면 support{'@'}tradelikecrazy.ai로
      이메일을 보내주세요.
    title: 일반도움
  header:
    subtitle: '기술적인 문제가 발생하여 도움을 받고자 하는 경우 support{''@''}tradelikecrazy.ai로 이메일을 보내주세요.'
    title: '기술적인 문제 '
  privacy: 개인정보 정책
  subtitle: 아래 양식을 작성하여 지원티켓을 제출하세요
  terms: 이용약관
  title: 티켓 제출하기
table:
  headers:
    action: 액션
    name: 이름
    terminationStartDate: '시작날짜 '
  referralTree:
    deposit: 입금
    direct: 직접매출
    level: 레벨
    referrals: '추천 '
    turnover: 매출
  staking:
    marketCap: 마켓캡
  trades:
    attachment: 사진 증명
    detail: 세부 사항
    entryDate: 시작날짜
    entryPrice: 시작가격
    exitDate: 끝 날짜
    exitPrice: 끝 가격
    margin: 마진
    pair: 페어
    profit: 수익
    type: 타입
  transactions:
    amount: 타입/금액
    date: 날짜/시간
    id: 거래 ID
    type: 타입
teamCenter:
  referralTree:
    title: 구조개요
toasts:
  success:
    general: 성공!
traders:
  feedTitle: 거래내역
  title: 트레이더
