bonuses:
  FEE_COMPENSATION: Compensación de tarifas
  FUND_TRANSFER: Transferencia de fondos
  FUND_TRANSFER_FEE: Tarifa de transferencia de fondos
  MATCHING_BONUS: Bonificación a juego
  PARTNER_TURNOVER: Unilevel Bonus
  PAYMENT: Pago
  PRODUCT_PURCHASE: Compra de productos
  PRODUCT_PURCHASE_FEE: Compra de producto
  PROFIT: Lucro
  PROFIT_LOCKED: Lucro
  REWARD: Recompensa
  TOKEN_EXCHANGE: Intercambio de fichas
  UNILEVEL_BONUS: Unilevel Bonus
  WALLET_TRANSFER: Intercambio de fichas
  WITHDRAW: Retirarse
  WITHDRAW_FEE: Tasa de retiro
button:
  about: Sobre
  addressCopied: El enlace ha sido copiado
  back: Volver
  cancel: Cancelar
  changeAvatar: Cambiar avatar
  close: Cerrar
  configureTotp: Conjunto 2FA
  confirm: Confirmar
  copied: El enlace ha sido copiado
  copyReflink: Copiar reflink
  createAccount: Crear cuenta
  deposit: Compra
  download: Descargar
  go: IR
  home: Hogar
  joinWaitlist: Comience el viaje!
  logIn: Iniciar sesión
  logOut: Cerrar sesión
  saveChanges: Guardar cambios
  submit: Presentar
  toggle_dark: Cambiar el modo oscuro
  toggle_langs: Cambiar idiomas
  updatePassword: Actualizar contraseña
confirmation:
  subtitle: >-
    Ingresa el código que te acabamos de enviar por correo electrónico para
    confirmar la acción.
  title: Confirmar acción
dialog:
  confirmation:
    title: Confirmación
  deposit:
    insufficientFunds: 'Fondos insuficientes. Por favor, haga un depósito primero.'
    title: Depositar fondos
fastStart:
  REFERRALLINK:
    title: Encuentra tu enlace de referencia
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: Comienzo
  TLCANNOUNCEMENTS:
    title: Encuentra actualizaciones de tlc
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: Primer deposito
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: Obtener apoyo
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: Obtener paquete
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: Obtener pdf de marketing
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: Ver tu equipo
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: Retirar Fondos
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: Resultados comerciales
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
forms:
  amount:
    label: Importe
  amountMax:
    label: 'Cantidad (máximo ${max})'
  avatar:
    edited: Avatar ha sido cambiado
    label: Avatar
  balance:
    label: Equilibrar
  bedType:
    label: Tipo de cama
  code:
    label: Código
  country:
    label: País
  deposit:
    label: Compra
  description:
    label: Descripción
  email:
    exists: Este email ya ha sido usado.
    invalid: Este correo electrónico no es válido.
    label: Correo electrónico
    labelRegistration: Su dirección de correo electrónico
    notExists: Este email no existe.
    required: Se requiere una dirección de correo electrónico
  emailOrNickname:
    label: Correo electrónico o apodo
  enabled:
    label: Habilitado
  endDate:
    label: Fecha de finalización
  endsAt:
    label: Termina en
  endsIn:
    label: Duración estimada
  errors:
    alphanumeric: Sólo se permiten letras y números.
    dateAfterNow: La fecha debe ser en el futuro
    number:
      positive: El valor debe ser superior a 0
    required: Este campo es necesario
    startDateAfterEndDate: La fecha de inicio debe ser anterior a la fecha de finalización
  expiryDate:
    label: Fecha de expiración
  field:
    label: Campo
  firstName:
    label: Nombre
    placeholder: Juan
  fullName:
    label: Nombre completo
    placeholder: John Doe
  id:
    label: IDENTIFICACIÓN
  lastName:
    label: Apellido
    placeholder: Doe
  name:
    label: Nombre
    placeholder: Nombre
  nationalNumber:
    label: Número Nacional
  newPassword:
    label: Nueva contraseña
  newPasswordRepeat:
    label: Repetir nueva contraseña
  nickname:
    exists: Este apodo ya ha sido utilizado
    label: Apodo
  oldPassword:
    label: Contraseña antigua
  password:
    error: Las contraseñas no coinciden
    label: Contraseña
  passwordRepeat:
    label: Repetir contraseña
  phoneNumber:
    errors:
      invalid: Número de teléfono no válido
    label: Número de teléfono
  phonePrefix:
    label: Prefijo
  postalCode:
    label: Código Postal
    placeholder: Código Postal
  price:
    label: Precio
  privacyPolicy:
    label: 'Estoy de acuerdo con {policy} y {tc}'
    policy:
      label: Política de privacidad
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: Términos y condiciones
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: Progreso
  quantity:
    label: Cantidad
  query:
    label: Consulta
    notExists: Dado usuario no existe.
  question:
    label: Cuestionar
    placeholder: Introduzca su pregunta aquí
  remarks:
    label: Observaciones
  response:
    label: Respuesta
  secondName:
    label: Segundo nombre
  status:
    active:
      label: Activo
    label: Status
    notActive:
      label: No activo
  timezone:
    label: Zona horaria
  title:
    label: Título
  type:
    label: Mecanografiar
  value:
    label: Valor
global:
  daysShort: DD
  hoursShort: HH
  minutesShort: MM
  people: Persona | Personas
history:
  fields:
    date: FECHAR
    description: DESCRIPCIÓN
    id: IDENTIFICACIÓN
    price: PRECIO
  filter:
    byBonus: Filtrar por bono
  type:
    PAYMENT: PAGO
    PRODUCT_PURCHASED: PRODUCTO COMPRADO
    SALES_COMMISSION: COMISIÓN DE VENTAS
    WITHDRAW: RETIRARSE
home:
  commission:
    currentLevel: Tu rango actual
    deposit: Depósito
    getLink: Generar reflujo
    requestCard: ''
    title: Tu cartera
    transfer: Transferencia
    withdraw: Retirarse
    yourSponsor: 'Su patrocinador es: {sponsor}'
  sales:
    activeReferrals: 'Referencias activas: {0}'
    title: Estadísticas de ventas
    total: 'Comisión total: {0} USD'
  statistics:
    of: '{0} de {1}'
    title: Resumen
  transactions:
    title: Historial de transacciones
  wallets:
    DEPOSIT:
      title: Billetera de depósito
    title: Saldos
    transfer:
      subtitle: Elegir la cartera preferida
      title: Billetera preferida para tomar fondos de
    WITHDRAW:
      title: Billetera de retiro
homeStats:
  direct: Volumen de negocios directo
  directReferrals: Remisiones directas
  team: Miembro del equipo
  volume: Team Turnover
marketing:
  presentation:
    cta: Obtener presentación
    subtitle: Lenguaje de presentación
    title: TLC Presentación descargar
  title: Comercialización
menu:
  blog: Anuncios
  bonuses: Transacciones
  contest: Promoción del liderazgo
  copyReflink: Copiar reflink
  dashboard: Salpicadero
  fastStart: Comienzo
  history: Historial de la Comisión
  knowledgeBase: Base de conocimientos
  logOut: Firmar
  marketing: Comercialización
  products: Productos
  settings: Ajustes
  shareReflink: Compartir reflejos
  structure: Su estructura
  support: Apoyo
  teamCenter: Centro de equipo
  traders: Traders
  you: Tú
navigation:
  title:
    blog: Anuncios
    confirmation: Confirmación
    dashboard: Dashboard
    fastStart: Comienzo rápido
    marketing: Comercialización
    packages: embalajes
    settings: ajustes
    statistics: Historial de transacciones
    structure: Su estructura
    support: Apoyo
    teamCenter: Team Center
    traders: Resumen de operaciones
not-found: No encontrado
ownedProducts:
  title: Paquetes propios
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: Depósito
    days: días
    infoAmount: 'Le permitirá comprar un paquete por valor de ${amount}'
    payTo: >-
      Por favor, envíe los fondos a la siguiente dirección USD TRC20. <br> El
      pago incluye una comisión del 2$.
    redirect: Pronto será redirigido
    warning: >-
      Por favor, envíe exactamente la misma cantidad que muestra el sistema de
      pago después de hacer clic en depósito.
  confirmation:
    text: ¿Estás seguro de eso?
  summerPromo:
    button: Continuar
    text: >-
      Debido a las vacaciones de verano hemos preparado descuentos adicionales!
      suscripción mensual es ahora 119$ y dos meses es 199$
    title: Promoción de verano!
  transfer:
    address: Dirección
    addressLabel: Dirección de Tron
    amount: Importe
    amountLabel: importe
    button: Transferencia
    status: Status
    success: La transferencia ha sido exitosa.
    text: >-
      Para transferir fondos a otro usuario rellene el formulario.<br> La
      comisión del 1% se cargará a tu monedero.
    title: Transferencia de fondos
  withdraw:
    address: Dirección
    addressLabel: Dirección de Tron
    amount: Importe
    amountLabel: importe
    button: Retirarse
    status: Status
    success: Se ha iniciado la retirada
    text: >-
      Introduzca la dirección de red tron a la que desea recibir una
      transferencia USDT.
    title: Retirar fondos
  withdrawSummary:
    title: Retirar resumen
products:
  inputPrice: Seleccione el precio del paquete a continuación para proceder.
  owned:
    limit: 'Límite disponible:'
    none: Todavía no tienes ningún paquete.
    title: Paquetes de propiedad
  timeToEnd: Hora de terminar
  title: Comprar un paquete
settings:
  authentication:
    title: Autenticación
  personal:
    title: Información personal
  profile:
    cta: Subir nuevo
    language: Lengua
    subtitle: Cómo te ve otro usuario
    title: Tu avatar
  sponsor:
    title: Su patrocinador
  telegram:
    title: Telegram Connection
signup:
  alreadyRegistered: Ya registrado?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: Forme parte de la revolución comercial.
    title: La primera vez que el comercio de IA
  privacy: Política de privacidad
  regulations: |
    Estoy de acuerdo con TradeLikeCrazy.ai {terms} y {privacy}
  subtitle: >-
    Has sido invitado por {nickname} al proyecto. Rellena el siguiente
    formulario para continuar.
  terms: Términos y condiciones
  title: ¡Únete a nosotros ahora!
statistics:
  max: Máx
  sum: Suma
  sumDay: Suma de hoy
  sumMonth: Suma mensual
structure:
  activeUntil: Activo hasta
  copyReflink: Copiar reflink
  directReferrals: Dirige
  inactive: Inactivo
  invested: Depositado
  joined: Unido
  referrals: Remisiones
  search: Buscador
  showDetails: Mostrar detalles
  title: Árbol de referencia
  totalPackages: Total de paquetes
  turnover: Volumen de negocios
support:
  alreadyRegistered: Ya registrado?
  footer:
    subtitle: >-
      Si tiene algún tipo de desafíos con respecto a los productos y le gustaría
      obtener ayuda de nosotros, por favor escriba un correo electrónico a
      support{'@'}tradelikecrazy.ai
    title: Apoyo General
  header:
    subtitle: >-
      Si usted tiene cualquier tipo de chanllenges técnicos y le gustaría
      obtener ayuda de nosotros por favor escriba un correo electrónico a
      support{'@'}tradelikecrazy.ai
    title: Problema técnico
  privacy: Política de privacidad
  subtitle: rellenar el siguiente formulario para enviar un ticket de soporte.
  terms: Términos y condiciones
  title: Enviar boleto
table:
  headers:
    action: Acción
    name: Nombre
    terminationStartDate: Fecha de inicio
  referralTree:
    deposit: Depósito
    direct: Ventas directas
    level: Nivelar
    referrals: Remisiones
    turnover: Volumen de negocios
  staking:
    marketCap: Límite de mercado
  trades:
    attachment: Prueba fotográfica
    detail: Detalle
    entryDate: Fecha de entrada
    entryPrice: Precio de entrada
    exitDate: Fecha de salida
    exitPrice: Precio de salida
    margin: Margen
    pair: Emparejar
    profit: Lucro
    type: Mecanografiar
  transactions:
    amount: Tipo/importe
    date: Fecha/hora
    id: Identificación de transacción
    type: Mecanografiar
teamCenter:
  referralTree:
    title: Resumen de la estructura
toasts:
  success:
    general: Éxito!
traders:
  feedTitle: Historial traders
  title: Traders
