// register vue composition api globally
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import generatedRoutes from 'virtual:generated-pages'
import { setupLayouts } from 'virtual:generated-layouts'
import 'virtual:svg-icons-register'
import * as Sentry from '@sentry/vue'
import { browserTracingIntegration, replayIntegration } from '@sentry/vue'
import App from './App.vue'

import './fonts'

// windicss layers
import 'virtual:windi-base.css'
import 'virtual:windi-components.css'
// your custom styles here
import './styles/main.css'
import './styles/toasts.css'
import './styles/tables.css'
// windicss utilities should be the last style import
import 'virtual:windi-utilities.css'
// windicss devtools support (dev only)
import 'virtual:windi-devtools'

const app = createApp(App)

const routes = setupLayouts(generatedRoutes)
const router = createRouter({ history: createWebHistory(), routes })

Sentry.init({
  app,
  dsn: 'https://3cfc3583db3431b8810c2e3d386bbcfe@o956606.ingest.sentry.io/4506762804264960',
  tracePropagationTargets: ['localhost', /^https:\/\/app\.tradelikecrazy\.ai/],
  ignoreErrors: [
    'Unable to preload CSS',
    'Could not inject ethereum provider',
    'Could not inject tron provider',
    'Unable to preload CSS',
  ],
  integrations: [browserTracingIntegration(), replayIntegration()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
  replaysOnErrorSampleRate: 1,
})

app.use(router)

// install all modules under `modules/`
Object.values(import.meta.globEager('./modules/*.ts')).map((i) =>
  i.install?.({ app, router, routes }),
)

app.mount('#app')
