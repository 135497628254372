<script setup lang="ts">
import { useKeycloak } from '@baloise/vue-keycloak'
import * as Sentry from '@sentry/vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useToast } from '~/composables/useToast'
import { useUserStore } from '~/stores/user'
import AppInitialsIcon from '~/components/AppInitialsIcon.vue'

const us = useUserStore()
const logOut = function () {
  const { keycloak } = useKeycloak()
  keycloak.logout({
    redirectUri: 'https://tradelikecrazy.ai',
  })
}
const { errorToast, successToast } = useToast()

const { copy } = useClipboard()
const { share } = useShare()
const { t } = useI18n()
const copyFunc = async (txt: string) => {
  try {
    await copy(txt)
    successToast(t('button.copied'))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  }
}
const shareFunc = async (txt: string) => {
  try {
    await share({ url: txt })
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  }
}

const shareReflink = function () {
  shareFunc(store.slugUrls[0].url)
}

const copyReflink = function () {
  copyFunc(store.slugUrls[0].url)
}
const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  { name: t('menu.copyReflink'), href: '#', onClick: copyReflink },
  { name: t('menu.shareReflink'), href: '#', onClick: shareReflink },
  { name: t('menu.logOut'), href: '#', onClick: logOut },
]
const store = useUserStore()

function telegramLoadedCallbackFunc() {
  console.log('script is loaded')
}

function yourCallbackFunction(user) {
  // gets user as an input
  // id, first_name, last_name, username,
  // photo_url, auth_date and hash
  console.log(user)
}
</script>

<template>
  <Menu as="div" class="ml-3 relative">
    <div>
      <MenuButton class="hover:cursor-pointer"
        ><div class="flex flex-row bg-primary-light-darker shadow-sm text-black rounded-2xl px-6 py-1 sm:py-2 space-x-4">
          <dl class="text-left">
            <dt class="font-bold sm:text-lg">{{ us.fullName }}</dt>
            <dd>{{ us.nickname }}</dd>
          </dl>
          <div>
            <AppInitialsIcon class="w-14 h-14">{{ us.initials }}</AppInitialsIcon>
          </div>
        </div></MenuButton
      >
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-100"
      >
        <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
          <a
            :href="item.href"
            :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
            @click="item.onClick"
          >
            {{ item.name }}
          </a>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>
