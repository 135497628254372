<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  title: {
    type: String,
    default: '',
  },
  withConfirmation: {
    type: Boolean,
    default: false,
  },
  confirmationTitle: {
    type: String,
    default: '',
  },
  confirmationContent: {
    type: String,
    default: '',
  },
})

const emit = defineEmits([
  'update:modelValue',
  'confirm',
  'reject',
  'close',
  'dialogTransitionAfterLeave',
])

const handleClose = () => {
  emit('update:modelValue', false)
  emit('close')
}

const isConfirmationOpen = ref(false)

const showConfirmation = () => {
  isConfirmationOpen.value = true
}
</script>

<template>
  <TransitionRoot as="template" :show="props.modelValue">
    <Dialog as="div" class="fixed z-20 text-black inset-0 overflow-y-auto" @close="handleClose">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            style="backdrop-filter: blur(6px)"
            class="fixed inset-0 bg-[#1356ff] bg-opacity-[0.12] transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          @after-leave="emit('dialogTransitionAfterLeave')"
        >
          <div
            class="bg-[#E5EEFF] inline-block align-bottom border border-[#D8E2F3] rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 lg:max-w-1/2"
          >
            <div class="app-dialog__content">
              <slot name="title">
                <DialogTitle as="h3" class="text-3xl font-medium text-theme-black font-semibold">
                  {{ props.title }}
                </DialogTitle>
              </slot>
              <slot :close="handleClose" :show-confirmation="showConfirmation"></slot>
              <div
                v-if="$slots.actions"
                class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
              >
                <slot
                  name="actions"
                  :close="handleClose"
                  :show-confirmation="showConfirmation"
                ></slot>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>

      <slot v-if="props.withConfirmation" name="confirmation" :is-open="isConfirmationOpen">
        <AppConfirmationDialog
          v-if="props.withConfirmation"
          v-model="isConfirmationOpen"
          :title="props.confirmationTitle"
          :content="props.confirmationContent"
          @confirm="() => emit('confirm')"
          @reject="() => emit('reject')"
        >
          <template v-if="$slots.confirmationTitle" #title>
            <slot name="confirmationTitle" />
          </template>
          <template #default>
            <slot name="confirmationContent" />
          </template>
          <template #actions="slotProps">
            <slot name="confirmationActions" v-bind="slotProps" />
          </template>
        </AppConfirmationDialog>
      </slot>
    </Dialog>
  </TransitionRoot>
</template>
