import type { MaybeRef } from '@vueuse/core'
import { useToast as useToastification } from 'vue-toastification'
import AppToastError from '~/components/base/toasts/AppToastError.vue'
import AppToastSuccess from '~/components/base/toasts/AppToastSuccess.vue'

type errorToastMessage = undefined | MaybeRef<string> | MaybeRef<{ message?: string }>

export function useToast() {
  const toast = useToastification()
  const { t } = useI18n()
  const errorToast = (message?: errorToastMessage, options?: any) => {
    message = unref(message)
    if (!message) {
      message = t('errors.general')
    }
    if (typeof message === 'object' && message.message) {
      message = t(`errors.server.${message.message}`)
    }

    return toast(
      {
        component: AppToastError,
        props: {
          content: message,
        },
      },
      {
        toastClassName: 'app-toast-wrap app-toast-error-wrap',
        bodyClassName: 'app-toast-body app-toast-error-body',
        hideProgressBar: true,
        ...options,
      },
    )
  }
  const successToast = (message = t('toasts.success.general'), options?: any) =>
    toast(
      {
        component: AppToastSuccess,
        props: {
          content: message,
        },
      },
      {
        toastClassName: 'app-toast-wrap app-toast-success-wrap',
        bodyClassName: 'app-toast-body app-toast-success-body',
        hideProgressBar: true,
        ...options,
      },
    )

  return { errorToast, successToast, toast }
}
