bonuses:
  FEE_COMPENSATION: Honorarausgleich
  FUND_TRANSFER: Guthaben-Übertragung
  FUND_TRANSFER_FEE: Überweisungsgebühr
  MATCHING_BONUS: Matching Bonus
  PARTNER_TURNOVER: Unilevel Bonus
  PAYMENT: Zahlung
  PRODUCT_PURCHASE: Produktkauf
  PRODUCT_PURCHASE_FEE: Produktkauf
  PROFIT: Gewinn
  PROFIT_LOCKED: Gewinn
  REWARD: Belohnen
  TOKEN_EXCHANGE: Token-Tausch
  UNILEVEL_BONUS: Unilevel Gewinn
  WALLET_TRANSFER: Token-Tausch
  WITHDRAW: Auszahlung
  WITHDRAW_FEE: Auszahlungsgebühr
button:
  about: Über
  addressCopied: Der Link wurde kopiert
  back: Zurück
  cancel: Abbrechen
  changeAvatar: Avatar ändern
  close: Schliessen
  configureTotp: 2FA einrichten
  confirm: Bestätigen
  copied: Der Link wurde kopiert
  copyReflink: Empfehlungslink kopieren
  createAccount: Konto erstellen
  deposit: Kaufen
  download: Download
  go: Los
  home: Home
  joinWaitlist: Beginne die Reise!
  logIn: Einloggen
  logOut: Ausloggen
  saveChanges: Änderungen sichern
  submit: Absenden
  toggle_dark: Umschalten Dark Mode
  toggle_langs: Sprachwechsel
  updatePassword: Passwort ändern
confirmation:
  subtitle: >-
    Geben Sie den Code ein, den wir Ihnen gerade per E-Mail gesendet haben, um
    die Aktion zu bestätigen.
  title: Aktion bestätigen
dialog:
  confirmation:
    title: Bestätigen
  deposit:
    insufficientFunds: Zu wenig Guthaben. Bitte erst eine Einzahlung machen.
    title: Guthaben einzahlen
fastStart:
  REFERRALLINK:
    title: Finden Sie Ihren Empfehlungslink
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: Loslegen
  TLCANNOUNCEMENTS:
    title: Finden Sie TLC-Updates
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: Erste Einzahlung
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: Hole dir Unterstützung
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: Paket abrufen
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: Marketing-PDF abrufen
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: Sehen Sie Ihr Team
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: Auszahlungen
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: Handelsergebnisse
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
forms:
  amount:
    label: Betrag
  amountMax:
    label: 'Betrag(max ${max})'
  avatar:
    edited: Der Avatar wurde geändert.
    label: Avatar
  balance:
    label: Saldo
  bedType:
    label: Bett-Typ
  code:
    label: Code
  country:
    label: Land
  deposit:
    label: Kauf
  description:
    label: Beschreibung
  email:
    exists: Diese Email wurde bereits verwendet.
    invalid: Diese Email ist ungültig.
    label: Email
    labelRegistration: Ihre Email-Adresse
    notExists: Diese Email existiert nicht.
    required: Emailadresse ist ein Pflichtfeld
  emailOrNickname:
    label: Email oder Benutzername
  enabled:
    label: Aktiviert
  endDate:
    label: Enddatum
  endsAt:
    label: endet am
  endsIn:
    label: Geschätzte Laufzeit
  errors:
    alphanumeric: Es sind nur Buchstaben und Zahlen erlaubt
    dateAfterNow: Das Datum muss in der Zukunft liegen
    number:
      positive: Der Wert muss größer als 0 sein
    required: Dies ist ein Pflichtfeld
    startDateAfterEndDate: Das Startdatum muss vor dem Enddatum liegen
  expiryDate:
    label: Ablaufdatum
  field:
    label: Feld
  firstName:
    label: Vorname
    placeholder: Max
  fullName:
    label: Voller Name
    placeholder: Max Mustermann
  id:
    label: Personalausweis
  lastName:
    label: Nachname
    placeholder: Mustermann
  name:
    label: Name
    placeholder: Name
  nationalNumber:
    label: Landesvorwahl
  newPassword:
    label: Neues Passwort
  newPasswordRepeat:
    label: Neues Passwort wiederholen
  nickname:
    exists: Der Benutzername wurde bereits verwendet
    label: Benutzername
  oldPassword:
    label: Altes Passwort
  password:
    error: Die Passworte stimmen nicht überein
    label: Passwort
  passwordRepeat:
    label: Passwort wiederholen
  phoneNumber:
    errors:
      invalid: Ungültige Telefonnummer
    label: Telefonnummer
  phonePrefix:
    label: Vorwahl
  postalCode:
    label: Postleitzahl
    placeholder: Postleitzahl
  price:
    label: Preis
  privacyPolicy:
    label: 'Ich stimme den {policy} und {tc} zu'
    policy:
      label: Datenschutzrichtlinie
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: Geschäftsbedingungen
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: Fortschritt
  quantity:
    label: Menge
  query:
    label: Abfrage
    notExists: Vorausgesetzt der Benutzer existiert nicht
  question:
    label: Frage
    placeholder: Geben Sie Ihre Frage hier ein
  remarks:
    label: Anmerkungen
  response:
    label: Antwort
  secondName:
    label: Zweiter Vorname
  status:
    active:
      label: 'Aktiv '
    label: Status
    notActive:
      label: Nicht aktiv
  timezone:
    label: Zeitzone
  title:
    label: Titel
  type:
    label: Typ
  value:
    label: Wert
global:
  daysShort: TT
  hoursShort: SS
  minutesShort: MM
  people: 'Personen '
history:
  fields:
    date: Datum
    description: Beschreibung
    id: Personalausweis
    price: Preis
  filter:
    byBonus: Filtern nach Bonusart
  type:
    PAYMENT: Zahlung
    PRODUCT_PURCHASED: Produkt gekauft
    SALES_COMMISSION: Verkaufsprovision
    WITHDRAW: Auszahlung
home:
  commission:
    currentLevel: Ihr aktueller Rang
    deposit: Einzahlung
    getLink: Empfehlungslink generieren
    requestCard: ''
    title: Ihre Wallet
    transfer: Übertragung
    withdraw: Auszahlung
    yourSponsor: 'Ihr Sponsor ist: {sponsor}'
  sales:
    activeReferrals: 'Aktive Empfehlungen: {0}'
    title: Verkaufsstatistik
    total: 'Gesamtprovision: {0} USD'
  statistics:
    of: '{0} of {1}'
    title: Übersicht
  transactions:
    title: Transaktionshistorie
  wallets:
    DEPOSIT:
      title: Einzahlungswallet
    title: Salden
    transfer:
      subtitle: Wähle bevorzugte Wallet
      title: Bevorzugte Wallet für Auszahlungen
    WITHDRAW:
      title: Auszahlungswallet
homeStats:
  direct: Direkter Umsatz
  directReferrals: Direkte Empfehlungen
  team: Teammitglieder
  volume: Teamumsatz
marketing:
  presentation:
    cta: Präsentation erhalten
    subtitle: Präsentationssprache
    title: TLC Präsentation herunterladen
  title: Marketing
menu:
  blog: Ankündigungen
  bonuses: Transaktionen
  contest: Aktion für Führungskräfte
  copyReflink: Empfehlungslink kopieren
  dashboard: Dashboard
  fastStart: Loslegen
  history: Provisionshistorie
  knowledgeBase: Wissens-Datenbank
  logOut: Ausloggen
  marketing: Marketing
  products: Produkte
  settings: Einstellungen
  shareReflink: Empfehlungslink teilen
  structure: Ihre Struktur
  support: Unterstützung
  teamCenter: Teambereich
  traders: Händler
  you: Sie
navigation:
  title:
    blog: Ankündigungen
    confirmation: Bestätigung
    dashboard: Ihr Dashboard
    fastStart: Schneller Start
    marketing: Marketing
    packages: Pakete
    settings: Einstellungen
    statistics: Transaktionshistorie
    structure: Ihre Struktur
    support: Unterstützung
    teamCenter: Teambereich
    traders: Handelsüberblick
not-found: nicht gefunden
ownedProducts:
  title: Eigene Pakete
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: Einzahlen
    days: Tage
    infoAmount: 'Es ermöglicht Ihnen, ein Paket im Wert von ${amount}'
    payTo: >-
      Bitte senden Sie Geld an die folgende USD TRC20-Adresse. <br> Die Zahlung
      beinhaltet eine Gebühr von 2$.
    redirect: Sie werden bald weitergeleitet
    warning: >-
      Bitte überweisen Sie genau den gleichen Betrag, der in Ihrem
      Zahlungssystem angezeigt wird, nachdem Sie auf „Einzahlen“ geklickt haben.
  confirmation:
    text: Sind Sie sicher?
  summerPromo:
    button: Weiter
    text: >-
      Wegen der Sommerferien haben wir Extra-Rabatte vorbereitet! Das Monatsabo
      kostet jetzt 119$ und das Zweimonatsabo 199$
    title: Sommer-Aktion
  transfer:
    address: Adresse
    addressLabel: Tron-Adresse
    amount: Betrag
    amountLabel: Betragsbezeichnung
    button: Übertragung
    status: Status
    success: Übertragung war erfolgreich.
    text: >-
      Um Geld an einen anderen Benutzer zu überweisen, füllen Sie das Formular
      aus.<br> Die Gebühr von 1 % wird von Ihrem Guthaben abgebucht.
    title: Guthabenübertragung
  withdraw:
    address: Adresse
    addressLabel: Tron-Adresse
    amount: Betrag
    amountLabel: Betragsbezeichnung
    button: Auszahlung
    status: Status
    success: Auszahlung wurde initiiert
    text: >-
      Bitte geben Sie die Tron-Netzwerkadresse ein, an die Sie einen
      USDT-Transfer erhalten möchten.
    title: Guthaben auszahlen
  withdrawSummary:
    title: Zusammenfassung zurückziehen
products:
  inputPrice: 'Paketpreis wählen, um fortzusetzen'
  owned:
    limit: 'Verfügbares Limit:'
    none: Sie besitzen bisher kein Paket
    title: Eigene Pakete
  timeToEnd: Zeit bis zum Ablauf
  title: Paket kaufen
settings:
  authentication:
    title: Authentifizierung
  personal:
    title: Persönliche Angaben
  profile:
    cta: Neu hochladen
    language: Sprache
    subtitle: Wie Sie von einem anderen Nutzer gesehen werden
    title: Ihr Avatar
  sponsor:
    title: ''
  telegram:
    title: Telegram-Verbindung
signup:
  alreadyRegistered: Schon registriert?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: Werde Teil der Trading-Revolution
    title: Die allererste Trading-KI
  privacy: Datenschutzerklärung
  regulations: 'Ich akzeptiere die {terms} und {privacy} von TradeLikeCrazy.ai'
  subtitle: >-
    Sie wurden von {nickname} zum Projekt eingeladen. Füllen sie das Formular
    unten aus, um fortzusetzen
  terms: AGB
  title: Jetzt beitreten
statistics:
  max: Max
  sum: Summe
  sumDay: Tagessumme
  sumMonth: Monatssumme
structure:
  activeUntil: Akiv bis
  copyReflink: Empfehlungslink kopieren
  directReferrals: Direkte
  inactive: Inaktive
  invested: Eingezahlt
  joined: Beigetreten
  referrals: Empfehlungen
  search: Person suchen
  showDetails: Details anzeigen
  title: Empfehlungsbaum
  totalPackages: Paketsumme
  turnover: Umsatz
support:
  alreadyRegistered: Schon registriert?
  footer:
    subtitle: >-
      Wenn Sie irgendeine Herausforderung in Bezug auf Produkte haben und Hilfe
      von uns erhalten möchten, senden Sie bitte eine E-Mail an
      support{'@'}tradelikecrazy.ai
    title: Allgemeiner Support
  header:
    subtitle: >-
      Wenn Sie technische Probleme haben und Hilfe von uns benötigen, schreiben
      Sie bitte eine E-Mail an support{'@'}tradelikecrazy.ai
    title: Technische Probleme
  privacy: Datenschutzerklärung
  subtitle: 'Füllen Sie das Formular unten aus, um ein Support-Ticket zu erstellen'
  terms: AGB
  title: Ticket erstellen
table:
  headers:
    action: Aktion
    name: Name
    terminationStartDate: Startdatum
  referralTree:
    deposit: Einzahlung
    direct: Direkte Verkäufe
    level: Ebene
    referrals: Empfehlungen
    turnover: Umsatz
  staking:
    marketCap: Marktkapitalisierung
  trades:
    attachment: Beweisfoto
    detail: Detail
    entryDate: Eintrittsdatum
    entryPrice: Einstandskurs
    exitDate: Ausstiegsdatum
    exitPrice: Ausstiegskurs
    margin: Margin
    pair: Paar
    profit: Gewinn
    type: Typ
  transactions:
    amount: Typ/Betrag
    date: Datum/Uhrzeit
    id: Transaktions-ID
    type: Typ
teamCenter:
  referralTree:
    title: Strukturüberblick
toasts:
  success:
    general: Erfolg!
traders:
  feedTitle: Handelshistorie
  title: Händler
