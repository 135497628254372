bonuses:
  FEE_COMPENSATION: Compensação de taxas
  FUND_TRANSFER: Transferência de fundos
  FUND_TRANSFER_FEE: Taxa de transferência de fundos
  MATCHING_BONUS: Bônus de correspondência
  PARTNER_TURNOVER: Bônus Unilevel
  PAYMENT: Pagamento
  PRODUCT_PURCHASE: Compra de produtos
  PRODUCT_PURCHASE_FEE: Compra de produto
  PROFIT: Lucro
  PROFIT_LOCKED: Lucrar
  REWARD: Recompensa
  TOKEN_EXCHANGE: Troca simbólica
  UNILEVEL_BONUS: Bônus Unilevel
  WALLET_TRANSFER: Troca simbólica
  WITHDRAW: Retirada
  WITHDRAW_FEE: Retirar Taxa
button:
  about: Sobre
  addressCopied: O link foi copiado
  back: Dorso
  cancel: Cancelar
  changeAvatar: Alterar avatar
  close: Fechar-se
  configureTotp: Conjunto 2FA
  confirm: Confirmar
  copied: O link foi copiado
  copyReflink: Copiar o reflink
  createAccount: Criar Conta
  deposit: Compra
  download: Download
  go: IR
  home: Casa
  joinWaitlist: Comece a jornada!
  logIn: Fazer login
  logOut: Sair
  saveChanges: Salvar alterações
  submit: ENVIAR
  toggle_dark: Alternar o modo escuro
  toggle_langs: Alterar idiomas
  updatePassword: Atualizar senha
confirmation:
  subtitle: Digite o código que acabamos de enviar por e-mail para confirmar a ação.
  title: Confirmar ação
dialog:
  confirmation:
    title: Confirmação
  deposit:
    insufficientFunds: 'Fundos insuficientes. Por favor, faça um depósito primeiro.'
    title: Depositar fundos
fastStart:
  REFERRALLINK:
    title: Encontre seu link de referência
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: Começo
  TLCANNOUNCEMENTS:
    title: Encontre atualizações de TLC
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: Primeiro depósito
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: Obtenha suporte
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: Obter pacote
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: Obtenha o PDF de marketing
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: Veja sua equipe
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: Retirar fundos
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: Resultados de negociação
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
forms:
  amount:
    label: Quantidade
  amountMax:
    label: 'Valor(max ${max})'
  avatar:
    edited: Avatar foi alterado
    label: Avatar
  balance:
    label: Equilíbrio
  bedType:
    label: Tipo de cama
  code:
    label: Código
  country:
    label: Sertão
  deposit:
    label: Compra
  description:
    label: Descrição
  email:
    exists: Este email já foi usado.
    invalid: Este e-mail é inválido.
    label: E-mail
    labelRegistration: Seu endereço de e-mail
    notExists: Esse email não existe.
    required: Endereço de e-mail é necessário
  emailOrNickname:
    label: E-mail ou apelido
  enabled:
    label: Habilitado
  endDate:
    label: Data final
  endsAt:
    label: Termina em
  endsIn:
    label: Duração estimada
  errors:
    alphanumeric: Somente letras e números são permitidos
    dateAfterNow: A data deve ser no futuro
    number:
      positive: O valor deve ser maior que 0
    required: Este campo é obrigatório
    startDateAfterEndDate: A data de início deve ser anterior à data de término
  expiryDate:
    label: Prazo de validade
  field:
    label: Campo
  firstName:
    label: Nome
    placeholder: João
  fullName:
    label: Nome Completo
    placeholder: Desconhecido
  id:
    label: ID
  lastName:
    label: Sobrenome
    placeholder: Corça
  name:
    label: Nome
    placeholder: Nome
  nationalNumber:
    label: Número Nacional
  newPassword:
    label: Nova senha
  newPasswordRepeat:
    label: Repetir nova senha
  nickname:
    exists: Este apelido já foi usado
    label: Apelido
  oldPassword:
    label: Senha antiga
  password:
    error: As senhas não correspondem
    label: Senha
  passwordRepeat:
    label: Repetir senha
  phoneNumber:
    errors:
      invalid: Número de telefone inválido
    label: Número de telefone
  phonePrefix:
    label: Prefixo
  postalCode:
    label: Código Postal
    placeholder: Código Postal
  price:
    label: Preço
  privacyPolicy:
    label: 'Concordo com {policy} e {tc}'
    policy:
      label: Política de Privacidade
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: Termos e Condições
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: Progresso
  quantity:
    label: Quantidade
  query:
    label: Buscar
    notExists: Este usuário não existe.
  question:
    label: Pergunta
    placeholder: Insira sua pergunta aqui
  remarks:
    label: Observações
  response:
    label: Resposta
  secondName:
    label: Segundo nome
  status:
    active:
      label: Ativo
    label: Status
    notActive:
      label: Não ativo
  timezone:
    label: Fuso horário
  title:
    label: Título
  type:
    label: Datilografar
  value:
    label: Valor
global:
  daysShort: DD
  hoursShort: HH
  minutesShort: MM
  people: Pessoa | Pessoas
history:
  fields:
    date: DATA
    description: DESCRIÇÃO
    id: ID
    price: PREÇO
  filter:
    byBonus: Filtrar por bônus
  type:
    PAYMENT: PAGAMENTO
    PRODUCT_PURCHASED: PRODUTO COMPRADO
    SALES_COMMISSION: COMISSÃO DE VENDAS
    WITHDRAW: RETIRADA
home:
  commission:
    currentLevel: Sua classificação atual
    deposit: Depósito
    getLink: Gerar reflink
    requestCard: ''
    title: Sua carteira
    transfer: Transferência
    withdraw: RETIRADA
    yourSponsor: 'Seu patrocinador é: {sponsor}'
  sales:
    activeReferrals: 'Referências ativas: {0}'
    title: Estatísticas de vendas
    total: 'Comissão total: {0} USD'
  statistics:
    of: '{0} of {1}'
    title: Sumário
  transactions:
    title: Histórico de transações
  wallets:
    DEPOSIT:
      title: Carteira de depósito
    title: Saldos
    transfer:
      subtitle: Escolha a carteira preferida
      title: Carteira preferida para receber fundos de
    WITHDRAW:
      title: Carteira de retirada
homeStats:
  direct: Investimento direto
  directReferrals: Referências diretas
  team: Membros da equipe
  volume: Faturamento da Equipe
marketing:
  presentation:
    cta: Obter apresentação
    subtitle: Linguagem de apresentação
    title: BAIXAR APRESENTAÇÃO TLC
  title: Comercialização
menu:
  blog: Anúncios
  bonuses: Transações
  contest: Promoção da liderança
  copyReflink: Copiar o reflink
  dashboard: Painel
  fastStart: Começo
  history: Histórico da comissão
  knowledgeBase: Base de conhecimento
  logOut: Sair
  marketing: Marketing
  products: Produtos
  settings: Configurações
  shareReflink: Compartilhar reflink
  structure: Sua estrutura
  support: Ajuda
  teamCenter: Equipe
  traders: Traders
  you: Você
navigation:
  title:
    blog: Anúncios
    confirmation: Confirmação
    dashboard: Seu painel
    fastStart: Início rápido
    marketing: Marketing
    packages: pacotes
    settings: configurações
    statistics: Histórico de transações
    structure: Sua Estrutura
    support: AJUDA
    teamCenter: Equipe
    traders: VISÃO GERAL DE TRADING
not-found: Não encontrado
ownedProducts:
  title: Pacotes próprios
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: Depósito
    days: Dias
    infoAmount: 'Permitir-lhe-á comprar um pacote no valor de ${amount}'
    payTo: >-
      Por favor, envie fundos para o seguinte endereço USD TRC20. <br> O
      pagamento inclui taxa de 2$.
    redirect: Será redireccionado em breve
    warning: >-
      Por favor, envie exatamente o mesmo montante que aparece no seu sistema de
      pagamento depois de clicar em depósito.
  confirmation:
    text: Tens a certeza disso?
  summerPromo:
    button: Continuar
    text: >-
      Devido às férias de verão, preparamos descontos extras! Assinatura mensal
      agora é de 119 $ e dois meses é de 199 $
    title: Promoção de verão!
  transfer:
    address: Endereço
    addressLabel: Endereço Tron
    amount: Quantidade
    amountLabel: amountLabel
    button: Transferência
    status: Status
    success: A transferência foi bem sucedida.
    text: >-
      Para transferir fundos para outro usuário preencha o formulário.<br> A
      taxa de 1% será cobrada de sua carteira.
    title: Transferência de fundos
  withdraw:
    address: Endereço
    addressLabel: Endereço Tron
    amount: Quantidade
    amountLabel: amountLabel
    button: RETIRADA
    status: Status
    success: Retirada foi iniciada
    text: >-
      Por favor, insira o endereço de rede tron para o qual você deseja receber
      uma transferência USDT.
    title: Retirar fundos
  withdrawSummary:
    title: Retirar resumo
products:
  inputPrice: Selecione o preço do pacote abaixo para prosseguir.
  owned:
    limit: 'Limite disponível:'
    none: Você ainda não possui nenhum pacote.
    title: Pacotes de propriedade
  timeToEnd: Hora de terminar
  title: Comprar pacotes
settings:
  authentication:
    title: Autenticação
  personal:
    title: Informações pessoais
  profile:
    cta: Carregar novo
    language: Linguajar
    subtitle: Como outro usuário vê você
    title: Seu avatar
  sponsor:
    title: Seu patrocinador
  telegram:
    title: Telegram Connection
signup:
  alreadyRegistered: Já se registou?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: Torne-se parte da revolução comercial.
    title: A primeira IA de negociação
  privacy: Política de privacidade
  regulations: |
    Eu concordo com TradeLikeCrazy.ai {terms} e {privacy}
  subtitle: >-
    Você foi convidado por {nickname} para o projecto. Preencha o formulário
    abaixo para continuar.
  terms: Termos e Condições
  title: Junte-se a nós agora!
statistics:
  max: Max
  sum: Soma
  sumDay: Soma de hoje
  sumMonth: Soma mensal
structure:
  activeUntil: Ativo até
  copyReflink: Copiar o reflink
  directReferrals: Referências diretas
  inactive: Inativo
  invested: Investimento Direto
  joined: Entrou
  referrals: Membros da equipe
  search: Busca por pessoa
  showDetails: Mostrar detalhes
  title: Árvore de referência
  totalPackages: Pacotes totais
  turnover: Faturamento da equipe
support:
  alreadyRegistered: Já se registou?
  footer:
    subtitle: >-
      Se você tiver qualquer tipo de desafios em relação aos produtos e gostaria
      de obter ajuda de nós, por favor envie um e-mail para
      suporte{'@'}tradelikecrazy.ai
    title: Apoio Geral
  header:
    subtitle: >-
      Se você tiver qualquer tipo de chanllenges técnicos e gostaria de obter
      ajuda de nós, por favor, envie um e-mail para
      suporte{'@'}tradelikecrazy.ai
    title: Problema técnico
  privacy: Política de privacidade
  subtitle: Preencha o formulário abaixo para enviar um ticket de suporte.
  terms: Termos e Condições
  title: Enviar ticket
table:
  headers:
    action: Ação
    name: Nome
    terminationStartDate: Data de início
  referralTree:
    deposit: Depósito
    direct: Vendas diretas
    level: Nivel
    referrals: Referências
    turnover: Faturamento da equipe
  staking:
    marketCap: Valor de mercado
  trades:
    attachment: Prova fotográfica
    detail: Detalhe
    entryDate: Data de entrada
    entryPrice: Preço de entrada
    exitDate: Data de saída
    exitPrice: Preço de saída
    margin: Margem
    pair: Par
    profit: Lucro
    type: Tipo
  transactions:
    amount: Tipo/Quantidade
    date: Data/hora
    id: ID da transação
    type: Descrição
teamCenter:
  referralTree:
    title: Visão geral da estrutura
toasts:
  success:
    general: Sucesso!
traders:
  feedTitle: Histórico de trades
  title: Traders
