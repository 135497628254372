bonuses:
  FEE_COMPENSATION: 费用补偿
  FUND_TRANSFER: 资金划转
  FUND_TRANSFER_FEE: 资金转账费
  MATCHING_BONUS: 匹配奖
  PARTNER_TURNOVER: 单极奖
  PAYMENT: 支付
  PRODUCT_PURCHASE: 产品购买
  PRODUCT_PURCHASE_FEE: 产品购买
  PROFIT: 利润
  PROFIT_LOCKED: 利润
  REWARD: 奖励
  TOKEN_EXCHANGE: 代币交换
  UNILEVEL_BONUS: 单极奖
  WALLET_TRANSFER: 代币交换
  WITHDRAW: 提现
  WITHDRAW_FEE: 提现手续费
button:
  about: 关于
  addressCopied: 链接已经被粘贴
  back: 上一页
  cancel: 取消
  changeAvatar: 改变头像
  close: 关闭
  configureTotp: 设定 2FA (双因素身份认证)
  confirm: 确认
  copied: 链接已经被粘贴
  copyReflink: 复制推荐链接
  createAccount: 创立账户
  deposit: 购买
  download: 下载
  go: 前往
  home: 首页
  joinWaitlist: 开始旅途！
  logIn: 登录
  logOut: 登出
  saveChanges: 保存更改
  submit: 提交
  toggle_dark: 切换暗模式
  toggle_langs: 更改语言
  updatePassword: 更新密码
confirmation:
  subtitle: 输入我们刚刚通过电子邮件发送给您的代码以确认操作。
  title: 确认操作
dialog:
  confirmation:
    title: 确认
  deposit:
    insufficientFunds: 资金不足。请先充钱。
    title: 充钱
  requestCard:
    preferredType: ''
    subtitle: ''
    title: ''
fastStart:
  REFERRALLINK:
    title: 找到您的推荐链接
    url: 'https://cdn.tlctrading.ai/videos%2FCN%2FREFERRALLINK.mp4'
  title: 开始旅途！
  TLCANNOUNCEMENTS:
    title: 查找 TLC 更新
    url: 'https://cdn.tlctrading.ai/videos%2FCN%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: 首次存款
    url: 'https://cdn.tlctrading.ai/videos%2FCN%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: 得到支持
    url: 'https://cdn.tlctrading.ai/videos%2FCN%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: 获取包
    url: 'https://cdn.tlctrading.ai/videos%2FCN%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: 获取营销 pdf
    url: 'https://cdn.tlctrading.ai/videos%2FCN%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: 查看你的团队
    url: 'https://cdn.tlctrading.ai/videos%2FCN%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: 提款
    url: 'https://cdn.tlctrading.ai/videos%2FCN%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: 交易结果
    url: 'https://cdn.tlctrading.ai/videos%2FCN%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
forms:
  amount:
    label: 数量
  amountMax:
    label: '数量(最多 ${max})'
  avatar:
    edited: 头像已经改变
    label: 头像
  balance:
    label: 余额
  bedType:
    label: 床型
  code:
    label: 代码
  country:
    label: 国家
  deposit:
    label: 购买
  description:
    label: 描述
  email:
    exists: 此电子邮箱已被使用
    invalid: 此电子邮箱无效
    label: 电子邮箱
    labelRegistration: 您的电子邮箱地址
    notExists: 此电子邮箱不存在
    required: 电子邮箱地址是必需的
  emailOrNickname:
    label: 电子邮箱或是昵称
  enabled:
    label: 启用
  endDate:
    label: 结束日期
  endsAt:
    label: 于……结束
  endsIn:
    label: 预计长度
  errors:
    alphanumeric: 仅允许字母和数字
    dateAfterNow: 日期必须是在未来
    number:
      positive: 值必须大于0
    required: 该字段为必填项
    startDateAfterEndDate: 开始日期必须早于结束日期
  expiryDate:
    label: 截止日期
  field:
    label: 存储单元
  firstName:
    label: 名
    placeholder: 小明
  fullName:
    label: 全名
    placeholder: 王小明
  id:
    label: ID
  lastName:
    label: 姓
    placeholder: 王
  name:
    label: 名
    placeholder: 名
  nationalNumber:
    label: 国际号码
  newPassword:
    label: 新密码
  newPasswordRepeat:
    label: 重复新密码
  nickname:
    exists: 此昵称已经被使用
    label: 分享链接区域名 使用后不可篡改
  oldPassword:
    label: 旧密码
  password:
    error: 密码不匹配
    label: 密码
  passwordRepeat:
    label: 重复密码
  phoneNumber:
    errors:
      invalid: 无效的电话号码
    label: 电话号码
  phonePrefix:
    label: 前缀
  postalCode:
    label: 邮政编码
    placeholder: 邮政编码
  price:
    label: 价格
  privacyPolicy:
    label: '我同意 {policy} 和 {tc}'
    policy:
      label: 隐私政策
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: 条款和条件
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: 进度
  quantity:
    label: 数量
  query:
    label: 询问
    notExists: 该使用者不存在
  question:
    label: 问题
    placeholder: 在此输入您的问题
  remarks:
    label: 评论
  response:
    label: 回复
  secondName:
    label: 第二名称
  status:
    active:
      label: 活跃的
    label: 状态
    notActive:
      label: 非活跃的
  timezone:
    label: 时区
  title:
    label: 标题
  type:
    label: 类型
  value:
    label: 数值
global:
  daysShort: 日
  hoursShort: 时
  minutesShort: 月
  people: 人
history:
  fields:
    date: 日期
    description: 叙述
    id: ID
    price: 价格
  filter:
    byBonus: 以奖金过滤
  type:
    PAYMENT: 支付
    PRODUCT_PURCHASED: 已购买产品
    SALES_COMMISSION: 销售提成
    WITHDRAW: 提现
home:
  commission:
    currentLevel: 您目前的排名
    deposit: 充钱
    getLink: 生成推荐链接
    requestCard: ''
    title: 您的钱包
    transfer: 划转
    withdraw: 提现
    yourSponsor: '您的推荐人是: {sponsor}'
  sales:
    activeReferrals: '活跃介绍人: {0}'
    title: 销售数据
    total: '总共提成: {0} USD'
  statistics:
    of: '{1} 之 {0}'
    title: 摘要
  transactions:
    title: 交易历史
  wallets:
    DEPOSIT:
      title: 充值钱包
    title: 余额
    transfer:
      subtitle: 选择要用的钱包
      title: 选择要提现的钱包
    WITHDRAW:
      title: 提现钱包
homeStats:
  direct: 直接成交量
  directReferrals: 直接介绍人
  team: 团队会员
  volume: 团队营业额
marketing:
  presentation:
    cta: 获得PPT
    subtitle: PPT 语言
    title: TLC PPT 下载
  title: 营销
menu:
  blog: 公告
  bonuses: 交易
  contest: 领导比赛活动
  copyReflink: 复制推荐链接
  dashboard: 仪表板
  fastStart: 启动
  history: 提成历史
  knowledgeBase: 知识库
  logOut: 登出
  marketing: 营销
  products: 产品
  settings: 设定
  shareReflink: 分享推荐链接
  structure: 您的结构
  support: 支持
  teamCenter: 团队中心
  traders: 交易者
  you: 您
navigation:
  title:
    blog: 公告
    confirmation: 确认
    dashboard: 您的仪表板
    fastStart: 快速启动
    marketing: 营销
    packages: 投资组合
    settings: 设定
    statistics: 交易历史
    structure: 您的结构
    support: 支持
    teamCenter: 团队中心
    traders: 交易概述
not-found: 未找到
ownedProducts:
  title: 拥有投资组合
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: 充钱
    days: 天
    infoAmount: '它可以让你购买价值${amount}美元的套餐。'
    payTo: 请将资金汇至以下 USD TRC20 地址。 <br> 付款包含 2 美元的手续费。
    redirect: 您将很快被重定向
    warning: 点击存款后，请发送与支付系统显示的金额完全相同的金额。
  confirmation:
    text: 您确定？
  summerPromo:
    button: 继续
    text: 基于暑假的关系，我们准备了额外的折扣!现在每月订阅费为119美元，两个月订阅费为199美元
    title: 夏季大促销！
  transfer:
    address: 地址
    addressLabel: 波场地址
    amount: 金额
    amountLabel: 金额标签
    button: 划转
    status: 状态
    success: 划转成功
    text: 要向其他用户转账，请填写表格。<br> 将从您的钱包中收取 1%的手续费。
    title: 资金划转
  withdraw:
    address: 地址
    addressLabel: 波场地址
    amount: 金额
    amountLabel: 金额标签
    button: 提现
    status: 状态
    success: 提现已经发起
    text: 请输入您希望收到USDT转账的波场网络地址。
    title: 资金提现
  withdrawSummary:
    title: 撤回摘要
products:
  inputPrice: 请选择以下投资组合价格然后继续
  owned:
    limit: '可用的上限:'
    none: 您尚未拥有任何投资组合
    title: 拥有投资组合
  timeToEnd: 结束时间
  title: 购买一个投资组合
settings:
  authentication:
    title: 验证
  personal:
    title: 个人信息
  profile:
    cta: 上传新的
    language: 语言
    subtitle: 另外一个使用者如何看见您
    title: 您的头像
  sponsor:
    title: 你的资助人
  telegram:
    title: 电报链接
signup:
  alreadyRegistered: 已经注册？
  header:
    caption: TradeLikeCrazy.ai
    subtitle: 成为交易革命的一员
    title: 有史以来第一个交易 AI
  privacy: 隐私声明
  regulations: '我同意 TradeLikeCrazy.ai {privacy} 和 {terms}'
  subtitle: '您已被{nickname}邀请参加该项目。填写下面的表格继续。'
  terms: 条款和条件
  title: 现在加入我们！
statistics:
  max: 最高
  sum: 总共
  sumDay: 今天总共
  sumMonth: 这个月总共
structure:
  activeUntil: 活跃至
  copyReflink: 复制推荐链接
  directReferrals: 直接推荐
  inactive: 未活跃
  invested: 已经充钱
  joined: 已经加入
  referrals: 推荐人
  search: 搜寻人
  showDetails: 显示细节
  title: 推荐树
  totalPackages: 所有投资组合
  turnover: 营业额
support:
  alreadyRegistered: 已经注册？
  footer:
    subtitle: '如果您对产品遇到任何类型的问题，并希望得到我们的帮助，请发送电子邮件至support{''@''} tradelikcracrazy .ai'
    title: 一般支持
  header:
    subtitle: '如果您遇到任何技术性问题，并希望得到我们的帮助，请发送电子邮件至support{''@''} tradelikcracrazy .ai'
    title: 技术性问题
  privacy: 隐私政策
  subtitle: 填一下表格以提交工单
  terms: 条款和条件
  title: 提交工单
table:
  headers:
    action: 动作
    name: 名称
    terminationStartDate: 开始日期
  referralTree:
    deposit: 充钱
    direct: 直接销售
    level: 代
    referrals: 推荐人
    turnover: 营业额
  staking:
    marketCap: 市值
  trades:
    attachment: 相片证明
    detail: 细节
    entryDate: 买入日期
    entryPrice: 买入价格
    exitDate: 卖出日期
    exitPrice: 卖出价格
    margin: 保证金
    pair: 对
    profit: 利润
    type: 类型
  transactions:
    amount: 类型/金额
    date: 日期/时间
    id: 交易 ID
    type: 类型
teamCenter:
  referralTree:
    title: 结构概述
toasts:
  success:
    general: 成功！
traders:
  feedTitle: 交易历史
  title: 交易员
