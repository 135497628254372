bonuses:
  FEE_COMPENSATION: Fee compensation
  FUND_TRANSFER: Fund transfer
  FUND_TRANSFER_FEE: Fund transfer fee
  MATCHING_BONUS: Matching Bonus
  PARTNER_TURNOVER: Unilevel Bonus
  PAYMENT: Payment
  PRODUCT_PURCHASE: Product purchase
  PRODUCT_PURCHASE_FEE: Product purchase
  PROFIT: Profit
  PROFIT_LOCKED: Profit
  REWARD: Reward
  TOKEN_EXCHANGE: Token exchange
  UNILEVEL_BONUS: Unilevel Bonus
  WALLET_TRANSFER: Token exchange
  WITHDRAW: Withdraw
  WITHDRAW_FEE: Withdraw Fee
button:
  about: About
  addressCopied: Link has been copied
  back: Back
  cancel: Cancel
  changeAvatar: Change avatar
  close: Close
  configureTotp: Set 2FA
  confirm: Confirm
  copied: Link has been copied
  copyReflink: Copy reflink
  createAccount: Create Account
  deposit: Purchase
  download: Download
  go: GO

  home: Home
  joinWaitlist: Start the journey!
  logIn: Log in
  logOut: Log out
  saveChanges: Save changes
  submit: Submit
  toggle_dark: Toggle dark mode
  toggle_langs: Change languages
  updatePassword: Update Password
confirmation:
  subtitle: Enter the code that we just emailed you to confirm the action.
  title: Confirm action
dialog:
  confirmation:
    title: Confirmation
  deposit:
    insufficientFunds: Insufficient funds. Please make a deposit first.
    title: Deposit funds
  requestCard:
    preferredType: Preferred card type
    subtitle: Fill in the form below to request your card.
    title: Request your card
fastStart:
  REFERRALLINK:
    title: Find your referral link
    url: 'https://cdn.tlctrading.ai/videos%2FREFERRALLINK.mp4'
  title: Getting started
  TLCANNOUNCEMENTS:
    title: Find tlc updates
    url: 'https://cdn.tlctrading.ai/videos%2FTLCANNOUNCEMENTS.mp4'
  TLCDEPOSIT:
    title: First deposit
    url: 'https://cdn.tlctrading.ai/videos%2FTLCDEPOSIT.mp4'
  TLCGETSUPPORT:
    title: Get support
    url: 'https://cdn.tlctrading.ai/videos%2FTLCGETSUPPORT.mp4'
  TLCPACKAGE:
    title: Get package
    url: 'https://cdn.tlctrading.ai/videos%2FTLCPACKAGE.mp4'
  TLCSLISEDECKMARKETING:
    title: Get marketing pdf
    url: 'https://cdn.tlctrading.ai/videos%2FTLCSLISEDECKMARKETING.mp4'
  TLCTEAMVIEW:
    title: See your team
    url: 'https://cdn.tlctrading.ai/videos%2FTLCTEAMVIEW.mp4'
  TLCWITHDRAW:
    title: Withdraw funds
    url: 'https://cdn.tlctrading.ai/videos%2FTLCWITHDRAW.mp4'
  TRADERERESULTS:
    title: Trading results
    url: 'https://cdn.tlctrading.ai/videos%2FTRADERERESULTS.mp4'
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
forms:
  amount:
    label: Amount
  amountMax:
    label: 'Amount(max ${max})'
  avatar:
    edited: Avatar has been changed
    label: Avatar
  balance:
    label: Balance
  bedType:
    label: Bed type
  code:
    label: Code
  country:
    label: Country
  deposit:
    label: Purchase
  description:
    label: Description
  email:
    exists: This email has already been used.
    invalid: This email is invalid.
    label: Email
    labelRegistration: Your email address
    notExists: This email does not exists.
    required: Email address is required
  emailOrNickname:
    label: Email or nickname
  enabled:
    label: Enabled
  endDate:
    label: End date
  endsAt:
    label: Ends at
  endsIn:
    label: Estimated duration
  errors:
    alphanumeric: Only letters and numbers are allowed
    dateAfterNow: The date must be in the future
    number:
      positive: Value must be greater than 0
    required: This field is required
    startDateAfterEndDate: The start date must be before the end date
  expiryDate:
    label: Expiry date
  field:
    label: Field
  firstName:
    label: First Name
    placeholder: John
  fullName:
    label: Full Name
    placeholder: John Doe
  id:
    label: ID
  lastName:
    label: Last Name
    placeholder: Doe
  name:
    label: Name
    placeholder: Name
  nationalNumber:
    label: National Number
  newPassword:
    label: New password
  newPasswordRepeat:
    label: Repeat new password
  nickname:
    exists: This nickname has already been used
    label: Nickname
  oldPassword:
    label: Old password
  password:
    error: Passwords don't match
    label: Password
  passwordRepeat:
    label: Repeat password
  phoneNumber:
    errors:
      invalid: Invalid phone number
    label: Phone Number
  phonePrefix:
    label: Prefix
  postalCode:
    label: Postal Code
    placeholder: Postal Code
  price:
    label: Price
  privacyPolicy:
    label: 'I agree with the {policy} and {tc}'
    policy:
      label: Privacy Policy
      url: 'https://cdn.tlctrading.ai/documents%2FPP.pdf'
    tc:
      label: Terms and Conditions
      url: 'https://cdn.tlctrading.ai/documents%2FTC.pdf'
  progress:
    label: Progress
  quantity:
    label: Quantity
  query:
    label: Query
    notExists: Given user does not exists.
  question:
    label: Question
    placeholder: Enter your question here
  remarks:
    label: Remarks
  response:
    label: Response
  secondName:
    label: Second name
  status:
    active:
      label: Active
    label: Status
    notActive:
      label: Not active
  timezone:
    label: Timezone
  title:
    label: Title
  type:
    label: Type
  value:
    label: Value
global:
  daysShort: DD
  hoursShort: HH
  minutesShort: MM
  people: Person | People
history:
  fields:
    date: DATE
    description: DESCRIPTION
    id: ID
    price: PRICE
  filter:
    byBonus: Filter by bonus
  type:
    PAYMENT: PAYMENT
    PRODUCT_PURCHASED: PRODUCT PURCHASED
    SALES_COMMISSION: SALES COMMISSION
    WITHDRAW: WITHDRAW
home:
  commission:
    currentLevel: Your current rank
    deposit: Deposit
    getLink: Generate reflink
    requestCard: Request card
    title: Your wallet
    transfer: Transfer
    withdraw: Withdraw
    yourSponsor: 'Your sponsor is: {sponsor}'
  sales:
    activeReferrals: 'Active Referrals: {0}'
    title: Sales statistics
    total: 'Total commission: {0} USD'
  statistics:
    of: '{0} of {1}'
    title: Summary
  transactions:
    title: Transaction history
  wallets:
    deposit:
      title: Select payment method
      subtitle: Select method
    DEPOSIT:
      title: Deposit wallet
    title: Balances
    transfer:
      subtitle: Choose preferred wallet
      title: Preferred wallet to take funds from
    WITHDRAW:
      title: Withdraw wallet
homeStats:
  direct: Direct turnover
  directReferrals: Direct referrals
  team: Team member
  volume: Team Turnover
marketing:
  presentation:
    cta: Get Presentation
    subtitle: Presentation language
    title: TLC Presentation download
  title: Marketing
menu:
  blog: Announcements
  bonuses: Transactions
  contest: Leadership promotion
  copyReflink: Copy reflink
  dashboard: Dashboard
  fastStart: Getting started
  history: Commission history
  knowledgeBase: Knowledge base
  logOut: Sign out
  marketing: Marketing
  products: Products
  settings: Settings
  shareReflink: Share reflink
  structure: Your structure
  support: Support
  teamCenter: Team center
  traders: Traders
  you: You
navigation:
  title:
    blog: Announcements
    confirmation: Confirmation
    dashboard: Your dashboard
    fastStart: Fast start
    marketing: Marketing
    packages: packages
    settings: settings
    statistics: Transaction history
    structure: Your Structure
    support: Support
    teamCenter: Team Center
    traders: Trading overview
not-found: Not found
ownedProducts:
  title: Owned packages
package:
  '20': Trial
  '50': Starter
  '100': Basic
  '250': Pro
  '500': Bronze
  '1000': Silver
  '2500': Gold
  '5000': Platinum
  '10000': Premium
  '25000': Premier
  '50000': Ultra
  '100000': Galaxy
popup:
  buySubscription:
    button: Deposit
    days: days
    infoAmount: 'It will allow you to buy a package worth of ${amount}'
    payTo: >-
      Please send funds to following USD TRC20 address. <br> The payment
      includes fee of 2$.
    redirect: You will be redirected soon
    warning: >-
      Please send exactly the same amount as shows you payment system after you
      click deposit.
  confirmation:
    text: Are you sure?
  summerPromo:
    button: Continue
    text: >-
      Due to summer holidays we have prepared extra discounts! Monthly
      subscription is now 119$ and two-months is 199$
    title: Summer promotion!
  transfer:
    address: Address
    addressLabel: Tron address
    amount: Amount
    amountLabel: amountLabel
    button: Transfer
    status: Status
    success: Transfer has been successful.
    text: >-
      To transfer funds to another user fill the form.<br> The fee of 1% will be
      charged from your wallet.
    title: Funds transfer
  withdraw:
    address: Address
    addressLabel: Tron address
    amount: Amount
    amountLabel: amountLabel
    button: Withdraw
    status: Status
    success: Withdraw has been initiated
    text: >-
      Please input tron network address on which You wish to receive a USDT
      transfer to.
    title: Withdraw funds
  withdrawSummary:
    title: Withdraw summary
products:
  inputPrice: Select package price below to proceed.
  owned:
    limit: 'Available limit:'
    none: You do not own any package yet.
    title: Owned Packages
  timeToEnd: Time to end
  title: Purchase a package
settings:
  authentication:
    title: Authentication
  personal:
    title: Personal information
  profile:
    cta: Upload new
    language: Language
    subtitle: How another user sees you
    title: Profile summary
  sponsor:
    title: Your sponsor
  telegram:
    title: Telegram Connection
signup:
  alreadyRegistered: Already registered?
  header:
    caption: TradeLikeCrazy.ai
    subtitle: Become part of trading revolution.
    title: The first ever trading AI
  privacy: Privacy Policy
  regulations: |
    I agree to TradeLikeCrazy.ai {terms} and {privacy}
  subtitle: >-
    You have been invited by {nickname} to the project. Fill the form below to
    continue.
  terms: Terms and Condtions
  title: Join us now!
statistics:
  max: Max
  sum: Sum
  sumDay: Today's sum
  sumMonth: Month's sum
structure:
  activeUntil: Active until
  copyReflink: Copy reflink
  directReferrals: Directs
  inactive: Inactive
  invested: Deposited
  joined: Joined
  referrals: Referrals
  search: Search person
  showDetails: Show details
  title: Referral tree
  totalPackages: Total packages
  turnover: Turnover
support:
  alreadyRegistered: Already registered?
  footer:
    subtitle: >-
      If you have any type of challenges regarding products and would like to
      get help from us please drop an Email at support{'@'}tradelikecrazy.ai
    title: General Support
  header:
    subtitle: >-
      If you have any type of technical chanllenges and would like to get help
      from us please drop an email at support{'@'}tradelikecrazy.ai
    title: Technical Problem
  privacy: Privacy Policy
  subtitle: fill form below to submit a support ticket.
  terms: Terms and Condtions
  title: Submit ticket
table:
  headers:
    action: Action
    name: Name
    terminationStartDate: Start date
  referralTree:
    deposit: Deposit
    direct: Direct sales
    level: Level
    referrals: Referrals
    turnover: Turnover
  staking:
    marketCap: Market cap
  trades:
    attachment: Photo proof
    detail: Detail
    entryDate: Entry Date
    entryPrice: Entry Price
    exitDate: Exit Date
    exitPrice: Exit Price
    margin: Margin
    pair: Pair
    profit: Profit
    type: Type
  transactions:
    amount: Type/Amount
    date: Date/Time
    id: Transaction ID
    type: Type
teamCenter:
  referralTree:
    title: Structure overview
toasts:
  success:
    general: Success!
traders:
  feedTitle: Trading history
  title: Traders
